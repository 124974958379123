import { useClientAPIQueryFn, useHasPermission } from '@insidedesk/tuxedo';
import { useQuery } from '@tanstack/react-query';
import { FacilityOption } from 'types';

export default function useFacilitiesQuery() {
  const { hasPermission } = useHasPermission();

  return useQuery(
    ['claims', 'facility-filter-options'],
    useClientAPIQueryFn<FacilityOption[]>(`claims/facility-filter-options`),
    {
      enabled: hasPermission('write:users'),
      suspense: true,
      useErrorBoundary: true,
    },
  );
}
