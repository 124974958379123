import { useClientAPIQueryFn } from '@insidedesk/tuxedo';
import { useQuery } from '@tanstack/react-query';
import { CollectorDetail } from 'types';

export default function useCollectorDetails(collectorId: number) {
  return useQuery(
    ['collectorDetails', collectorId],
    useClientAPIQueryFn<CollectorDetail>(`admin/collectors/${collectorId}`),
    { useErrorBoundary: true },
  );
}
