import { SortTab, TAB_HEIGHT } from '@insidedesk/tuxedo';
import { Stack, Tabs, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { useId } from 'react';
import { CREDENTIAL_SORT_ATTRIBUTE_LABELS as SORT_ATTRIBUTE_LABELS } from '../../constants';
import { sortAtom } from '../../state/credential-page';
import { CredentialSortAttribute as SortAttribute } from '../../types';

function DynamicSort({ labelId }: { labelId: string }) {
  const [sort, setSort] = useAtom(sortAtom);

  return (
    <Tabs
      aria-labelledby={labelId}
      value={sort.attribute}
      textColor='secondary'
      indicatorColor='secondary'
      sx={{ minHeight: TAB_HEIGHT, height: TAB_HEIGHT }}
    >
      {(['claim_count', 'collector_name', 'username'] as SortAttribute[]).map(
        (attribute) => (
          <SortTab
            key={attribute}
            label={SORT_ATTRIBUTE_LABELS[attribute]}
            value={attribute}
            currentSort={sort}
            onClick={() => setSort(attribute)}
          />
        ),
      )}
    </Tabs>
  );
}

export default function Sorting() {
  const labelId = useId();
  return (
    <Stack
      direction='row'
      spacing={1}
      alignItems='center'
      minHeight={TAB_HEIGHT}
    >
      <Typography id={labelId} fontSize='0.875rem' fontWeight={500}>
        Sort Order:
      </Typography>

      <DynamicSort labelId={labelId} />
    </Stack>
  );
}
