import { TwoToneHeader, useFlags, useHasPermission } from '@insidedesk/tuxedo';
import { LockOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { CredentialListItem } from 'components';
import { RedactedCredential } from 'types';
import './CredentialList.scss';

interface CredentialListProps {
  credentials: RedactedCredential[];
}

export default function CredentialList({ credentials }: CredentialListProps) {
  const { hasPermission } = useHasPermission();
  const flags = useFlags();
  const gridTemplateColumns = `[insurer] 1fr ${
    flags.addCredentialOpenClaimsField ? '[openClaims] 1fr' : ''
  } [taxID] 1fr [user] 1fr ${
    flags.removeCredentialLastAccessedField ? '' : '[lastAccessed] 1fr'
  } [status] auto`;

  return (
    <div
      className='credential-list-parent'
      data-testid='credential-list'
      style={{ flexGrow: 1, overflowY: 'auto' }}
    >
      {credentials.length ? (
        <Stack
          // When removing uniformCredentialListColumns, replace the stack with
          // a normal div or Box
          spacing={flags.uniformCredentialListColumns ? 0 : 3}
          sx={[
            Boolean(flags.uniformCredentialListColumns) && {
              display: 'grid',
              gridTemplateColumns,
              rowGap: 3,
            },
          ]}
        >
          {credentials.map((credential) => (
            <CredentialListItem
              key={credential.id}
              credential={credential}
              sx={{
                cursor: hasPermission('write:credentials')
                  ? 'pointer'
                  : undefined,
              }}
            />
          ))}
        </Stack>
      ) : (
        <NoCredentialsFound />
      )}
    </div>
  );
}

function NoCredentialsFound() {
  return (
    <Stack alignItems='center' justifyContent='center' height='100%'>
      <LockOutlined color='primary' sx={{ fontSize: 150 }} />
      <TwoToneHeader
        fontSize={48}
        fontWeight={600}
        mt={3}
        text={['No ', 'credentials ', 'found']}
      />
    </Stack>
  );
}
