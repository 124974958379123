import {
  LoadingSpinner,
  SortOrderToggle,
  useHasPermission,
} from '@insidedesk/tuxedo';
import { Close, PersonAddOutlined, Search } from '@mui/icons-material';
import {
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  TablePagination,
  useTheme,
} from '@mui/material';
import { UserList } from 'components';
import { useUsersQuery } from 'hooks';
import { useAtom } from 'jotai';
import { KeyboardEvent, Suspense, useEffect, useState } from 'react';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import { Link } from 'react-router-dom';
import {
  pageAtom,
  rowsPerPageAtom,
  searchAtom,
  sortByAtom,
  sortOrderAtom,
} from 'state/user-page';
import { UserSortBy } from 'types';
import { USER_SORT_OPTIONS } from '../../../constants';
import './UserPage.scss';

export default function UserPage() {
  const { hasPermission } = useHasPermission();
  return (
    <>
      <Grid
        container
        direction='row'
        px={3}
        pt={1}
        columnGap={2}
        alignItems='center'
      >
        <Grid item xs={5}>
          <SearchBar />
        </Grid>
        <Grid item xs={3}>
          <SortSelection />
        </Grid>
        <Grid item ml='auto'>
          {hasPermission('write:users') && <AddUserButton />}
        </Grid>
      </Grid>
      <Divider sx={{ mt: 1 }} />
      <Suspense fallback={<LoadingSpinner />}>
        <UserPageContent />
      </Suspense>
    </>
  );
}

function UserPageContent() {
  const [page, setPage] = useAtom(pageAtom);
  const [rowsPerPage, setRowsPerPage] = useAtom(rowsPerPageAtom);
  const { users, pagination, isFetching, isFetched } = useUsersQuery();
  const showProgress = isFetching && !isFetched;

  return (
    <>
      <LinearProgress
        data-testid='users-linear-progress'
        color='secondary'
        sx={{
          visibility: showProgress ? 'visible' : 'hidden',
          minHeight: '6px',
        }}
      />
      <UserList users={users} />
      <Divider sx={{ mb: 0 }} />
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-end'
        pr={3}
      >
        <TablePagination
          component='div'
          page={page}
          rowsPerPage={rowsPerPage}
          count={pagination?.total ?? 0}
          onPageChange={(_, page_) => setPage(page_)}
          onRowsPerPageChange={(e) =>
            setRowsPerPage(parseInt(e.target.value, 10))
          }
        />
      </Stack>
    </>
  );
}

function SearchBar() {
  const formContext = useForm();
  const searchFieldValue = formContext.watch('search');
  const [search, setSearch] = useAtom(searchAtom);
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);

  const handleKeyDown = (
    e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (e.key === 'Escape' && isTextFieldFocused) {
      setSearch('');
      formContext.reset();
    }
  };

  useEffect(() => {
    if (search) formContext.setValue('search', search);
  }, [search, formContext]);

  return (
    <FormContainer
      formContext={formContext}
      onSuccess={(values) => setSearch(values.search)}
    >
      <TextFieldElement
        fullWidth
        size='small'
        name='search'
        placeholder='Search'
        onFocus={() => setIsTextFieldFocused(true)}
        onBlurCapture={() => setIsTextFieldFocused(false)}
        InputProps={{
          onKeyDown: (e) => handleKeyDown(e),
          startAdornment: (
            <InputAdornment position='start'>
              <IconButton type='submit'>
                <Search color='primary' />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: searchFieldValue ? (
            <InputAdornment position='end'>
              <IconButton
                onClick={() => {
                  setSearch('');
                  formContext.reset();
                }}
              >
                <Close />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
    </FormContainer>
  );
}

function SortSelection() {
  const [sortBy, setSortBy] = useAtom(sortByAtom);
  const [sortOrder, setSortOrder] = useAtom(sortOrderAtom);

  return (
    <FormControl fullWidth>
      <Stack direction='row' alignItems='center' gap={1}>
        <InputLabel id='sort'>Sort</InputLabel>
        <Select
          fullWidth
          size='small'
          labelId='sort'
          label='Sort'
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value as UserSortBy)}
        >
          {USER_SORT_OPTIONS.map((option) => (
            <MenuItem key={option} value={option}>
              {snakeToTitleCase(option)}
            </MenuItem>
          ))}
        </Select>
        <SortOrderToggle sortOrder={sortOrder} onClick={setSortOrder} />
      </Stack>
    </FormControl>
  );
}

function AddUserButton() {
  const { palette } = useTheme();
  return (
    <Button
      component={Link}
      to='add'
      variant='contained'
      startIcon={<PersonAddOutlined />}
      sx={{
        textTransform: 'uppercase',
        height: '100%',
        '&:hover': {
          backgroundColor: palette.secondary.main,
          color: 'white',
        },
      }}
    >
      Add user
    </Button>
  );
}

function snakeToTitleCase(str: string) {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
