import { BlurableInput, MaskFullStory } from '@insidedesk/tuxedo';
import { Button, Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';
import {
  AutocompleteElement,
  FormContainer,
  PasswordElement,
  PasswordRepeatElement,
  SelectElement,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui';
import {
  Collector,
  CollectorDetail,
  CredentialPatchParams,
  RedactedCredential,
} from 'types';
import { CredentialPostParams } from '../CredentialAddModal/CredentialAddModal';

import './CredentialAddEditForm.scss';

type QuestionKey = `question${number}`;
type AnswerKey = `answer${number}`;

type DefaultCredentialAddEditFormValues = {
  [key: QuestionKey]: string;
  [key: AnswerKey]: string | undefined;
  username: string;
  password?: string;
  confirmPassword?: string;
  taxId?: string;
  collector?: Collector;
  denticalPin?: string;
};

type CredentialAddValues = {
  taxId: string;
  collector: Collector;
  username: string;
  password: string;
};

type CredentialEditValues = DefaultCredentialAddEditFormValues;

export type AddCredential = (data: CredentialPostParams) => void;
export type PatchCredential = (data: CredentialPatchParams) => void;
type taxIds = string[];
type Collectors = Collector[];

export type CredentialAddEditFormProps = {
  credential?: RedactedCredential;
  collectorDetails?: CollectorDetail;
  addCredential?: AddCredential;
  patchCredential?: PatchCredential;
  taxIds?: taxIds;
  collectors?: Collectors;
  isSubmitting?: boolean;
} & (
  | {
      credential?: undefined;
      addCredential: AddCredential;
      taxIds: taxIds;
      collectors: Collectors;
      isSubmitting: boolean;
    }
  | { credential: RedactedCredential; patchCredential: PatchCredential }
);

export const DENTICAL_QUESTION_NAME = 'Dentical Financials Access Pin';
export const SECURITY_QUESTION_ANSWER_PLACEHOLDER = '********';

export function CredentialAddEditForm(props: CredentialAddEditFormProps) {
  const {
    credential,
    collectorDetails,
    addCredential,
    patchCredential,
    taxIds,
    collectors,
    isSubmitting,
  } = props;

  const isAdd = credential === undefined;

  const questionKeys = Array(collectorDetails?.required_questions ?? 0)
    .fill(null)
    .map((_, i) => `question${i + 1}` as QuestionKey);

  const answerKeys = Array(collectorDetails?.required_questions ?? 0)
    .fill(null)
    .map((_, i) => `answer${i + 1}` as AnswerKey);

  const credentialQuestions = useMemo(
    () => [...(credential?.questions ?? [])],
    [credential?.questions],
  );

  const defaultValues: DefaultCredentialAddEditFormValues = {
    taxId: undefined,
    collector: undefined,
    username: credential?.username ?? '',
    denticalPin: undefined,
    ...Object.fromEntries(
      questionKeys.map((key) => {
        const matchIndex = credentialQuestions.findIndex((q) =>
          collectorDetails?.security_questions?.includes(q.question),
        );

        if (matchIndex !== -1) {
          /**
           * Remove the question from the list of credential questions so we
           * don't match it again.
           */
          const [{ question }] = credentialQuestions.splice(matchIndex, 1);
          return [key, question];
        }

        return [key, undefined];
      }),
    ),
    ...Object.fromEntries(answerKeys.map((key) => [key, undefined])),
  };

  const formContext = useForm<DefaultCredentialAddEditFormValues>({
    defaultValues,
  });
  const { isValid: formValid, errors: formErrors } = formContext.formState;

  const handleAddCredential = useCallback(
    (values: CredentialAddValues) => {
      const { taxId, collector, username, password } = values;
      const postParams: CredentialPostParams = {
        tax_id: taxId.trim().replace(/-/g, ''),
        collector_id: collector.id,
        username: username.trim(),
        password: password.trim(),
      };
      if (!credential) addCredential(postParams);
    },
    [addCredential, credential],
  );

  const handlePatchCredential = useCallback(
    (values: CredentialEditValues) => {
      const { username, password, denticalPin } = values;

      const patchParams: CredentialPatchParams = {
        username: username.trim(),
      };

      if (password) {
        patchParams.password = password.trim();
      }

      if (denticalPin) {
        patchParams.questions = [
          {
            question: DENTICAL_QUESTION_NAME,
            answer: denticalPin.trim(),
          },
        ];
      }

      questionKeys.forEach((key, i) => {
        const question = values[key]?.trim();
        const answer = values[answerKeys[i]]?.trim() ?? null;
        if (question) {
          if (!patchParams.questions) {
            patchParams.questions = [{ question, answer }];
          } else {
            patchParams.questions.push({ question, answer });
          }
        }
      });

      if (credential) patchCredential(patchParams);
    },
    [credential, patchCredential, questionKeys, answerKeys],
  );

  /**
   * Triggers a re-render on change so we can dynamically disable security
   * question dropdown options as well as show/hide the input fields for each
   * dropdown
   */
  formContext.watch(questionKeys);

  return (
    <FormContainer
      formContext={formContext}
      onSuccess={(values) =>
        isAdd
          ? handleAddCredential(values as CredentialAddValues)
          : handlePatchCredential(values)
      }
    >
      <Stack alignItems='center'>
        <Stack id='add-edit-input-fields' direction='column' spacing={4}>
          {!credential && (
            <>
              <AutocompleteElement
                name='taxId'
                label='Tax ID'
                rules={{ required: isAdd }}
                options={taxIds ?? []}
                autocompleteProps={{
                  isOptionEqualToValue: (a, b) => a === b,
                  groupBy: (option) => option.split('-')[0],
                  blurOnSelect: true,
                  autoComplete: true,
                  fullWidth: true,
                  sx: { display: 'flex', justifyContent: 'center' },
                }}
              />
              <AutocompleteElement
                name='collector'
                label='Portal'
                rules={{ required: isAdd }}
                options={collectors ?? []}
                autocompleteProps={{
                  getOptionLabel: (option) => option.name,
                  isOptionEqualToValue: (option, value) =>
                    option.id === value.id,
                  groupBy: (option) => {
                    const label = option.name;
                    const firstLetter = label[0].toUpperCase();
                    return /[0-9]/.test(firstLetter) ? '0-9' : firstLetter;
                  },
                  blurOnSelect: true,
                  autoComplete: true,
                  fullWidth: true,
                  sx: { display: 'flex', justifyContent: 'center' },
                }}
              />
            </>
          )}
          <MaskFullStory>
            <TextFieldElement
              label='Username'
              name='username'
              onFocus={(e) => e.target.select()}
              required
              type='text'
              InputProps={{ inputComponent: BlurableInput }}
            />
            <PasswordElement
              iconColor='primary'
              label='Password'
              name='password'
              required={isAdd}
            />
            <PasswordRepeatElement
              iconColor='primary'
              inputProps={{ onBlur: () => formContext.trigger() }}
              label='Confirm Password'
              name='confirmPassword'
              required={isAdd}
              onChange={() => {
                if (!formValid && 'confirmPassword' in formErrors) {
                  formContext.trigger();
                }
              }}
              passwordFieldName='password'
            />
          </MaskFullStory>
          {credential && credential.insurer?.match(/denti-cal/i) !== null ? (
            <MaskFullStory>
              <PasswordElement
                iconColor='primary'
                label={DENTICAL_QUESTION_NAME}
                name='denticalPin'
                onFocus={(e) => e.target.select()}
                required
              />
            </MaskFullStory>
          ) : (
            questionKeys.map((questionKey, i) => {
              const { defaultValues: defaultFormValues, dirtyFields } =
                formContext.formState;
              const formValues = formContext.getValues();
              return (
                <MaskFullStory key={questionKey}>
                  <SelectElement
                    label={`Security Question ${i + 1}`}
                    name={questionKey}
                    options={(collectorDetails?.security_questions ?? []).map(
                      (q) => ({
                        label: q,
                        id: q,
                        disabled: questionKeys.some((k) => formValues[k] === q),
                      }),
                    )}
                    required
                    key={questionKey}
                  />
                  <PasswordElement
                    iconColor='primary'
                    {...(defaultFormValues?.[questionKey] !== undefined &&
                    !dirtyFields[questionKey]
                      ? { placeholder: SECURITY_QUESTION_ANSWER_PLACEHOLDER }
                      : { label: `Security Question ${i + 1} Answer` })}
                    name={answerKeys[i]}
                    key={answerKeys[i]}
                    hidden={formValues[questionKey] === undefined}
                    InputProps={{ inputComponent: BlurableInput }}
                    required={
                      defaultFormValues?.[questionKey] === undefined ||
                      dirtyFields[questionKey]
                    }
                  />
                </MaskFullStory>
              );
            })
          )}
        </Stack>
        <Button
          type='submit'
          name='submit'
          color='primary'
          variant='contained'
          disabled={!formValid || isSubmitting}
          sx={{ mt: 1 }}
        >
          Validate
        </Button>
      </Stack>
    </FormContainer>
  );
}
