import {
  CredentialStatus,
  propertyIn,
  SidebarItem,
  SidebarMenu,
  useHasPermission,
} from '@insidedesk/tuxedo';
import { Divider, Stack } from '@mui/material';
import { useCredentialsQuery, useUserStatusCounts } from 'hooks';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { filterByAtom as credentialFilterByAtom } from 'state/credential-page';
import { filterByAtom as userFiltyByAtom } from 'state/user-page';
import { CredentialSideBarLabel, UserFilterBy } from 'types';

type CredentialStatusCounts = Record<CredentialStatus | '', number>;

export const credentialFilterOptionLabelMapping: Record<
  CredentialStatus | '',
  CredentialSideBarLabel
> = {
  issues: 'issues',
  initial: 'needs setup',
  new: 'validating',
  ok: 'ok',
  '': 'all',
};

export const userFilterOptionLabelMapping: Record<UserFilterBy, string> = {
  active: 'Active',
  inactive: 'Inactive',
  invited: 'Invited',
  deactivated: 'Deactivated',
  all_facilities: 'All Access',
  single_facility: 'Single Facility',
  multi_facility: 'Multi Facility',
  all: 'All',
};

const overFlowProps = {
  height: '100%',
  maxHeight: '100%',
  overflow: 'auto',
};

export default function SideBar() {
  const { hasPermission } = useHasPermission();

  return (
    <Stack spacing={1} sx={overFlowProps}>
      {hasPermission('read:credentials') && (
        <SidebarMenu
          label='Payor Portal Management'
          to='/credentials'
          includeChildren
        >
          <CredentialOptionsGroup
            options={['issues', 'initial', 'new', 'ok', '']}
          />
          <Divider sx={{ my: 0.5 }} />
        </SidebarMenu>
      )}
      {hasPermission('read:users') && (
        <SidebarMenu label='User Management' to='/users'>
          <UserOptionsGroup
            options={[
              'active',
              'all_facilities',
              'single_facility',
              'multi_facility',
            ]}
          />
          <Divider sx={{ my: 0.5 }} />
          <UserOptionsGroup
            options={['inactive', 'invited', 'deactivated', 'all']}
          />
          <Divider sx={{ my: 0.5 }} />
        </SidebarMenu>
      )}
    </Stack>
  );
}

function CredentialOptionsGroup(props: { options: (CredentialStatus | '')[] }) {
  const { options } = props;
  const { data } = useCredentialsQuery({
    enabled: window.location.pathname.includes('credentials'),
  });

  const statusCounts: CredentialStatusCounts = useMemo(() => {
    const totals = data?.data.statusTotals || {
      issues: 0,
      new: 0,
      ok: 0,
      initial: 0,
    };
    const values = Object.values(totals);
    const sum = values.reduce((total: number, next: number) => total + next, 0);
    return {
      ...totals,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '': sum,
    };
  }, [data?.data.statusTotals]);
  const [filterBy, setFilterBy] = useAtom(credentialFilterByAtom);
  return (
    <>
      {options.map((option) => (
        <SidebarItem
          key={option}
          data-fs-element={`credential-option-tab-${option}`}
          label={credentialFilterOptionLabelMapping[option]}
          active={filterBy === option}
          onClick={() => setFilterBy(option)}
          spacing={1}
          color={option === 'issues' ? 'error' : undefined}
          count={
            propertyIn(statusCounts, option)
              ? statusCounts[option as keyof typeof statusCounts]
              : undefined
          }
        />
      ))}
    </>
  );
}

function UserOptionsGroup(props: { options: UserFilterBy[] }) {
  const { options } = props;
  const { data: statusCounts } = useUserStatusCounts();
  const [filterBy, setFilterBy] = useAtom(userFiltyByAtom);
  return (
    <>
      {options.map((option) => (
        <SidebarItem
          key={option}
          data-fs-element={`user-option-tab-${option}`}
          label={userFilterOptionLabelMapping[option]}
          active={filterBy === option}
          spacing={1}
          onClick={() => setFilterBy(option)}
          count={
            propertyIn(statusCounts, option)
              ? statusCounts[option as keyof typeof statusCounts]
              : undefined
          }
        />
      ))}
    </>
  );
}
