import { useClientAPIQueryFn } from '@insidedesk/tuxedo';
import { useQuery } from '@tanstack/react-query';
import { Collector } from 'types';

export default function useCollectorList() {
  return useQuery(
    ['collectorList'],
    useClientAPIQueryFn<Collector[]>('vault/collectors/?insurer_only=false'),
    {
      useErrorBoundary: true,
    },
  );
}
