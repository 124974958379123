import { useClientAPIMutationFn } from '@insidedesk/tuxedo';
import { useMutation } from '@tanstack/react-query';
import { User, UserPatchArgs } from 'types';

export default function useUpdateUser(id: User['id']) {
  return useMutation(
    useClientAPIMutationFn<void, UserPatchArgs>(`users/${id}`, {
      method: 'PATCH',
    }),
  );
}
