import { useClientAPIQueryFn, useHasPermission } from '@insidedesk/tuxedo';
import { useQuery } from '@tanstack/react-query';
import { UserStatusCounts } from 'types';

export default function useUserStatusCounts() {
  const { hasPermission } = useHasPermission();
  const queryFn = useClientAPIQueryFn<UserStatusCounts>('users/status-counts');

  return useQuery(['user-status-counts'], queryFn, {
    enabled:
      hasPermission('read:users') && window.location.pathname.includes('users'),
  });
}
