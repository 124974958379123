import { ErrorCard, HTTPError } from '@insidedesk/tuxedo';
import MoodBadOutlinedIcon from '@mui/icons-material/MoodBadOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { Box, Card } from '@mui/material';
import { PropsWithChildren, ReactElement } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { noticeError } from '../utils';

export default function ErrorElement(props: { error?: unknown }): ReactElement {
  const { error } = props;
  noticeError(error);
  return (
    <ErrorLayout>
      <ErrorCardSelector {...props} />
    </ErrorLayout>
  );
}

function ErrorLayout({ children }: PropsWithChildren) {
  return (
    <Box
      overflow='auto'
      width='100%'
      display='flex'
      flexDirection='column'
      flexGrow={1}
    >
      <Card
        variant='flat'
        sx={{
          padding: '60px',
          margin: 3,
          background: '#FCFDFE',
          flexGrow: 1,
          flexShrink: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {children}
      </Card>
    </Box>
  );
}

function ErrorCardSelector(props: { error?: unknown }) {
  const { error: fallbackError } = props;
  const routeError = useRouteError();
  const error = fallbackError ?? routeError;
  let statusCode: number | undefined;
  if (error instanceof HTTPError) {
    statusCode = error.response.status;
  } else if (error instanceof Response) {
    statusCode = error.status;
  } else if (isRouteErrorResponse(error)) {
    statusCode = error.status;
  }

  if (statusCode === 403) return <Forbidden />;
  if (statusCode === 404) return <NotFound />;
  return <GenericError status={statusCode} />;
}
function Forbidden() {
  return (
    <ErrorCard
      Icon={PersonOffOutlinedIcon}
      headerText={['Not ', 'authorized']}
      headerSize={48}
      content={
        <>
          Sorry, you&apos;re not authorized for this feature. If this is a
          mistake please contact your office administrator.
        </>
      }
    />
  );
}

function NotFound() {
  return (
    <ErrorCard
      Icon={SentimentDissatisfiedIcon}
      headerText={['4', '0', '4']}
      content='Sorry, page not found.'
    />
  );
}

function GenericError(props: { status?: number }) {
  const { status } = props;
  const header = status
    ? status?.toString().split('').concat([' ', 'E', 'RR', 'OR'])
    : ['E', 'RR', 'OR'];
  const message =
    'Sorry, we encountered an error. The team has been notified and are looking into the issue.';

  return (
    <ErrorCard
      Icon={MoodBadOutlinedIcon}
      headerText={header}
      content={message}
    />
  );
}
