import removeEmpty from './removeEmpty';
import removeNullish from './removeNullish';

export default function createSearchParams(
  params: Record<string, QueryValue | readonly QueryValue[] | undefined | null>,
): URLSearchParams {
  return new URLSearchParams(
    toStringValues(removeNullish(removeEmpty(params))),
  );
}

type QueryValue = number | string | boolean;

function toStringValues(obj: object): string[][] {
  return Object.entries(obj).flatMap(([key, value]) => {
    if (Array.isArray(value)) {
      return value.map((item) => [key, String(item)]);
    }
    return [[key, String(value)]];
  });
}
