import { CredentialStatus } from '@insidedesk/tuxedo';
import { atom } from 'jotai';
import {
  atomWithReset,
  atomWithStorage,
  createJSONStorage,
  RESET,
} from 'jotai/utils';
import { CascadingStorage } from 'utils';
import {
  CREDENTIAL_DEFAULT_SORT,
  CREDENTIAL_DEFAULT_SORT_DIRECTION,
  DEFAULT_CREDENTIAL_FILTER,
} from '../constants';
import {
  CollectorType,
  CredentialSortAttribute,
  PersistedPagination,
} from '../types';

/**
 * XXX: If you add a new storage atom, be sure to load it in the AtomPreloader
 * component and set it to a non-default value in AtomPreloader.test.tsx to
 * prevent future regressions.
 */

const storage = new CascadingStorage('credentials');

const paginationStorageAtom = atomWithStorage<PersistedPagination>(
  'pagination',
  { page: 0, rowsPerPage: 25 },
  createJSONStorage(() => storage),
);

export const pageAtom = atom(
  (get) => get(paginationStorageAtom).page,
  (get, set, page: number) => {
    set(paginationStorageAtom, (prev) => ({ ...prev, page }));
  },
);

export const rowsPerPageAtom = atom(
  (get) => get(paginationStorageAtom).rowsPerPage,
  (get, set, rowsPerPage: number) => {
    set(paginationStorageAtom, (prev) => ({ ...prev, page: 0, rowsPerPage }));
  },
);

export const sortAttributeAtom = atomWithReset(
  CREDENTIAL_DEFAULT_SORT.attribute,
);
export const sortDirectionAtom = atomWithReset(
  CREDENTIAL_DEFAULT_SORT.direction,
);
export const sortAtom = atom(
  (get) => ({
    attribute: get(sortAttributeAtom),
    direction: get(sortDirectionAtom),
  }),
  (get, set, update: CredentialSortAttribute | typeof RESET) => {
    const current = get(sortAttributeAtom);
    if (update === RESET) {
      set(sortAttributeAtom, RESET);
      set(sortDirectionAtom, RESET);
    } else if (current === update) {
      const direction = get(sortDirectionAtom);
      set(sortDirectionAtom, direction === 'asc' ? 'desc' : 'asc');
    } else {
      set(sortAttributeAtom, update);
      set(sortDirectionAtom, CREDENTIAL_DEFAULT_SORT_DIRECTION[update]);
    }
    set(pageAtom, 0);
  },
);

export const taxIdStorageAtom = atomWithStorage(
  'taxId',
  '',
  createJSONStorage(() => storage),
);

export const taxIdAtom = atom(
  (get) => get(taxIdStorageAtom),
  (get, set, taxId: string) => {
    set(pageAtom, 0);
    set(taxIdStorageAtom, taxId);
  },
);

const collectorTypeStorageAtom = atomWithStorage<CollectorType | 'all'>(
  'collectorType',
  'all',
  createJSONStorage(() => storage),
);

export const collectorTypeAtom = atom(
  (get) => get(collectorTypeStorageAtom),
  (get, set, collectorType: CollectorType) => {
    set(pageAtom, 0);
    set(collectorTypeStorageAtom, collectorType);
  },
);

const filterStorageAtom = atomWithStorage<CredentialStatus | ''>(
  'filter',
  DEFAULT_CREDENTIAL_FILTER,
  createJSONStorage(() => storage),
);

export const filterByAtom = atom(
  (get) => get(filterStorageAtom),
  (get, set, filterBy: CredentialStatus | '') => {
    set(pageAtom, 0);
    set(filterStorageAtom, filterBy);
  },
);
