import { useClientAPIMutationFn, User } from '@insidedesk/tuxedo';
import { useMutation } from '@tanstack/react-query';

export default function useDeactivateUser(id: User['id']) {
  return useMutation(
    useClientAPIMutationFn(`users/${id}/deactivate`, {
      method: 'POST',
    }),
  );
}
