import { TwoToneHeader } from '@insidedesk/tuxedo';
import { PersonOffOutlined } from '@mui/icons-material';
import { Grid, Stack } from '@mui/material';
import { UserListItem } from 'components';
import { User } from 'types';

export default function UserList({ users }: { users: User[] | undefined }) {
  return (
    <Grid
      data-testid='user-list'
      container
      direction='column'
      wrap='nowrap'
      px={3}
      // account for the minheight of the linear progress bar (6px)
      pt='2px'
      pb={1}
      gap={1}
      flexGrow={1}
      sx={{ overflowY: 'auto' }}
    >
      {users?.length === 0 ? (
        <NoUsersFound />
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {users?.map((user, i) => (
            <Grid item key={user.id}>
              <UserListItem user={user} index={i} />
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
}

function NoUsersFound() {
  return (
    <Stack alignItems='center' justifyContent='center' height='100%'>
      <PersonOffOutlined color='primary' sx={{ fontSize: 150 }} />
      <TwoToneHeader
        fontSize={48}
        fontWeight={600}
        mt={3}
        text={['No ', 'users ', 'found']}
      />
    </Stack>
  );
}
