import { useClientAPIQueryFn, useHasPermission } from '@insidedesk/tuxedo';
import { useQuery } from '@tanstack/react-query';
import { ExtendedUser, User } from 'types';

export default function useUserQuery(id: User['id']) {
  const { hasPermission } = useHasPermission();
  return useQuery(
    ['user', id],
    useClientAPIQueryFn<ExtendedUser>(`users/${id}`),
    {
      enabled: hasPermission('read:users'),
      suspense: true,
      useErrorBoundary: true,
    },
  );
}
