import {
  BlurableInput,
  CenterInPage,
  ExtendedDivider,
  LoadingSpinner,
  PHI,
  propertyIn,
  SyncButton,
  TwoToneHeader,
  useAuth0RoleMapping,
  useFlags,
  useSnackbar,
  useUserProfile,
} from '@insidedesk/tuxedo';
import {
  Close,
  ErrorOutline,
  InfoOutlined,
  NoAccountsOutlined,
  PersonOutlined,
  Search,
} from '@mui/icons-material';
import {
  Alert,
  alpha,
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  dialogClasses,
  FormControlLabel,
  FormControlLabelProps,
  formLabelClasses,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  Switch,
  switchClasses,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  typographyClasses,
  useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useDeactivateUser, useReactivateUser } from 'hooks';
import { camelCase } from 'lodash';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import {
  CheckboxElement,
  Controller,
  FormContainer,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui';
import {
  ExtendedUser,
  FacilityOption,
  UserPatchArgs,
  UserPostArgs,
  UserRole,
} from 'types';
import { removeEmpty, removeNullish } from 'utils';

export const FORM_CONTAINER_PADDING = 3;
const URL_PATTERN_REGEX =
  // eslint-disable-next-line no-useless-escape
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
const DEFAULT_ROLES = ['dashboard'];
export const EDITABLE_ROLES = new Set<UserRole['name']>([
  'dashboard',
  'inside dial',
  'insurance credential management',
  'user management',
]);

const FormSectionHeader = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 500,
  fontSize: '0.875rem',
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
}));

const StyledTextFieldElement = styled(TextFieldElement)(({ theme }) => ({
  minWidth: '300px',
  margin: theme.spacing(2),
  background: 'white',
  [`& .${formLabelClasses.root}`]: {
    color: alpha(theme.palette.text.primary, 0.7),
  },
}));

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

type AddEditUserFormValues = {
  first_name: string;
  last_name: string;
  display_name: string;
  title: string;
  email: string;
  picture: string;
  all_facility_access: boolean;
  roles: Record<UserRole['name'], boolean>;
  facility_ids: Record<FacilityOption['id'], boolean>;
};

type CreateUser = (data: UserPostArgs) => void;
type UpdateUser = (data: UserPatchArgs) => void;

type AddEditUserFormProps = {
  roles: UserRole[];
  facilityOptions: FacilityOption[];
  user?: ExtendedUser;
  createUser?: CreateUser;
  updateUser?: UpdateUser;
  isSubmitting: boolean;
} & (
  | { user?: undefined; createUser: CreateUser }
  | { user: ExtendedUser; updateUser: UpdateUser }
);

export default function AddEditUserForm(props: AddEditUserFormProps) {
  const { roles, facilityOptions, createUser, updateUser, isSubmitting, user } =
    props;
  const currentUser = useUserProfile();
  const flags = useFlags();
  const [search, setSearch] = useState('');
  const theme = useTheme();
  const auth0RoleMapping = useAuth0RoleMapping();

  const formContext = useForm<AddEditUserFormValues>({
    defaultValues: {
      first_name: user?.first_name ?? '',
      last_name: user?.last_name ?? '',
      display_name: user?.display_name ?? '',
      title: user?.user_metadata?.title ?? '',
      email: user?.email ?? '',
      picture: user?.picture ?? '',
      all_facility_access: user?.all_facility_access ?? false,
      roles: Object.fromEntries(
        roles.map((role) => [
          role.name,
          user
            ? user.roles.includes(role.name)
            : DEFAULT_ROLES.includes(role.name),
        ]),
      ),
      facility_ids: Object.fromEntries(
        facilityOptions.map((facility) => [
          facility.id,
          user?.facility_ids.includes(facility.id) ?? false,
        ]),
      ),
    },
  });

  const allFacilityAccess = formContext.watch('all_facility_access');

  const handleSelectAllFacilities = useCallback(
    () =>
      formContext.setValue(
        'facility_ids',
        Object.fromEntries(
          facilityOptions.map((facility) => [facility.id, true]),
        ),
        { shouldDirty: true },
      ),
    [facilityOptions, formContext],
  );

  const handleClearAllFacilities = useCallback(
    () =>
      formContext.setValue(
        'facility_ids',
        Object.fromEntries(
          facilityOptions.map((facility) => [facility.id, false]),
        ),
        { shouldDirty: true },
      ),
    [facilityOptions, formContext],
  );

  useEffect(() => {
    /**
     * If a user previously had all_facility_access enabled, it makes sense to
     * clear all the checkboxes if all_facility_access is toggled off.
     */
    if (!user?.all_facility_access) return;
    if (allFacilityAccess) handleSelectAllFacilities();
    if (!allFacilityAccess) handleClearAllFacilities();
  }, [
    user?.all_facility_access,
    allFacilityAccess,
    handleClearAllFacilities,
    handleSelectAllFacilities,
  ]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isSubmitting ? (
        <CenterInPage>
          <LoadingSpinner
            description={`${
              user ? 'Updating' : 'Creating'
            } user... This may take a moment.`}
          />
        </CenterInPage>
      ) : (
        <FormContainer
          FormProps={{ className: 'add-edit-user-form' }}
          formContext={formContext}
          onSuccess={(data) => {
            if (user === undefined) {
              createUser(formatCreateFormData(data));
              return;
            }

            const { dirtyFields } = formContext.formState;
            const dirtyData = Object.fromEntries(
              Object.entries(data).filter(([k]) => k in dirtyFields),
            );

            updateUser(formatUpdateFormData(dirtyData));
          }}
        >
          <FormSectionHeader>User information</FormSectionHeader>
          <ExtendedDivider extend={FORM_CONTAINER_PADDING} />
          <Stack
            direction='row'
            justifyContent='center'
            my={3}
            gap={2}
            flexWrap='wrap'
          >
            <Card variant='outlined'>
              <Stack alignItems='center' p={2} spacing={2}>
                <PHI>
                  <AvatarPreview src={formContext.watch('picture')} />
                </PHI>
                <TextFieldElement
                  name='picture'
                  label='Avatar URL'
                  validation={{
                    pattern: {
                      value: URL_PATTERN_REGEX,
                      message: 'Must be a valid URL',
                    },
                  }}
                  sx={{
                    maxWidth: '185px',
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    [`& .${formLabelClasses.root}`]: {
                      color: alpha(theme.palette.text.primary, 0.7),
                    },
                  }}
                />
              </Stack>
            </Card>
            <Card
              variant='outlined'
              sx={{ px: 3, py: 2, background: alpha('#D9D9D9', 0.1) }}
            >
              <Stack direction='row'>
                <StyledTextFieldElement
                  name='first_name'
                  label='First Name'
                  required
                  InputProps={{ inputComponent: BlurableInput }}
                />
                <StyledTextFieldElement
                  name='last_name'
                  label='Last Name'
                  InputProps={{ inputComponent: BlurableInput }}
                />
              </Stack>
              <Stack direction='row'>
                <StyledTextFieldElement
                  name='display_name'
                  label='Display Name'
                  InputProps={{ inputComponent: BlurableInput }}
                />
                <StyledTextFieldElement name='title' label='Title' />
              </Stack>
              <Stack direction='row'>
                <StyledTextFieldElement
                  type='email'
                  name='email'
                  label='Email Address'
                  required
                  disabled={user !== undefined}
                  InputProps={{ inputComponent: BlurableInput }}
                />
              </Stack>
            </Card>
            {user && user.id !== currentUser.id && (
              <Stack justifyContent='center' ml={2}>
                <UserActivationToggle user={user} />
              </Stack>
            )}
          </Stack>
          <FormSectionHeader>Roles/permissions</FormSectionHeader>
          <ExtendedDivider extend={FORM_CONTAINER_PADDING} />
          <Stack
            direction='row'
            flexWrap='wrap'
            justifyContent='center'
            my={3}
            gap={2}
          >
            {roles
              .filter((role) => {
                if (!EDITABLE_ROLES.has(role.name)) return false;
                const flagKey = camelCase(`grant ${role.name} role`);
                return propertyIn(flags, flagKey) ? flags[flagKey] : true;
              })
              .map((role) => (
                <LabelledSwitch
                  key={role.id}
                  name={`roles.${role.name}`}
                  label={auth0RoleMapping[role.name]}
                  disabled={!role.editable}
                />
              ))}
          </Stack>
          {facilityOptions.length > 0 && (
            <>
              <FormSectionHeader>Facility access</FormSectionHeader>
              <ExtendedDivider extend={FORM_CONTAINER_PADDING} />
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                my={3}
                columnGap={2}
              >
                {!allFacilityAccess && (
                  <FacilitySearch search={search} setSearch={setSearch} />
                )}
                {currentUser.all_facility_access && (
                  <Box sx={{ position: 'relative' }}>
                    <CustomWidthTooltip
                      title={
                        <Typography
                          color='white'
                          textAlign='center'
                          fontWeight={600}
                          sx={{ p: 1 }}
                        >
                          Activating this button will give the user access to
                          all current facilities utilizing InsideDesk and will
                          also automatically give the user access to any
                          facilities added to InsideDesk in the future.
                        </Typography>
                      }
                      arrow
                      placement='top'
                    >
                      <InfoOutlined
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: theme.spacing(1.5),
                          transform: 'translateY(-50%)',
                          color: allFacilityAccess
                            ? 'white'
                            : theme.palette.primary.main,
                        }}
                      />
                    </CustomWidthTooltip>
                    <LabelledSwitch
                      name='all_facility_access'
                      label='Always allow access to all facilities'
                      sx={{ paddingLeft: theme.spacing(6) }}
                    />
                  </Box>
                )}
                {!allFacilityAccess && (
                  <>
                    <Button onClick={handleSelectAllFacilities}>
                      <Typography
                        variant='h6'
                        textTransform='uppercase'
                        color={theme.palette.text.primary}
                      >
                        Select All
                      </Typography>
                    </Button>
                    <Button onClick={handleClearAllFacilities}>
                      <Typography
                        variant='h6'
                        textTransform='uppercase'
                        color={theme.palette.text.primary}
                      >
                        Clear
                      </Typography>
                    </Button>
                  </>
                )}
              </Stack>
            </>
          )}
          {!allFacilityAccess && (
            <FacilityOptionsGrid>
              {facilityOptions
                .filter((facility) =>
                  new RegExp(search, 'i').test(facility.name ?? ''),
                )
                .map((facility) => (
                  <CheckboxElement
                    key={facility.id}
                    name={`facility_ids.${facility.id}`}
                    label={<PHI>{facility.name}</PHI>}
                    sx={{ p: 1 }}
                    data-testid='facility-option'
                  />
                ))}
            </FacilityOptionsGrid>
          )}
          <ExtendedDivider extend={FORM_CONTAINER_PADDING} />
          <Stack alignItems='center' my={3}>
            <Button
              type='submit'
              variant='contained'
              disabled={!formContext.formState.isDirty}
              sx={{ width: '100px' }}
            >
              Save
            </Button>
          </Stack>
        </FormContainer>
      )}
    </>
  );
}

function AvatarPreview(props: { src: string }) {
  const { src } = props;
  const theme = useTheme();
  return (
    <Avatar
      src={src}
      variant='rounded'
      sx={{
        width: '185px',
        height: '185px',
        border: `2px dotted ${theme.palette.primary.main}`,
        background: 'white',
      }}
    >
      <PersonOutlined
        color='primary'
        sx={{
          opacity: 0.25,
          fontSize: '150px',
        }}
      />
    </Avatar>
  );
}

function LabelledSwitch(
  props: {
    name: string;
  } & Omit<FormControlLabelProps, 'control'>,
) {
  const { name, sx, ...rest } = props;
  const theme = useTheme();
  return (
    <Controller
      name={name}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          {...rest}
          control={<Switch checked={value} onChange={onChange} />}
          labelPlacement='start'
          className={value ? 'checked' : ''}
          sx={{
            padding: theme.spacing(1),
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(3),
            margin: 0,
            background: 'white',
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: `${theme.shape.borderRadius}px`,
            /* eslint-disable @typescript-eslint/naming-convention */
            [`& .${switchClasses.thumb}`]: { color: '#737373 !important' },
            [`& .${switchClasses.track}`]: {
              background: `${alpha('#737373', 0.5)} !important`,
            },
            [`& .${typographyClasses.root}`]: {
              color: '#737373',
              textTransform: 'capitalize',
              fontSize: '0.875rem',
              fontWeight: 600,
            },
            '&.checked': {
              border: `1px solid ${theme.palette.success.main}`,
              background: theme.palette.success.main,
              [`& .${typographyClasses.root}`]: { color: 'white' },
              [`& .${switchClasses.thumb}`]: { color: 'white !important' },
              [`& .${switchClasses.track}`]: {
                background: `${alpha('#FFFFFF', 0.5)} !important`,
              },
            },
            /* eslint-enable @typescript-eslint/naming-convention */
            ...(rest.disabled ? { opacity: 0.5 } : {}),
            ...sx,
          }}
        />
      )}
    />
  );
}

function UserActivationToggle(props: { user: ExtendedUser }) {
  const { user } = props;
  const { id, active } = user;
  const { palette, shape } = useTheme();
  const [deactivationModalOpen, setDeactivationModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const reactivateUserMutation = useReactivateUser(id);
  const deactivateUserMutation = useDeactivateUser(id);

  const invalidateQueries = async () => {
    await queryClient.invalidateQueries(['user-list'], {
      type: 'all',
    });
    await queryClient.invalidateQueries(['user-status-counts'], {
      type: 'all',
    });
    await queryClient.invalidateQueries(['user', id]);
  };

  const handleDeactivateUser = () => {
    deactivateUserMutation.mutate(null, {
      async onSuccess() {
        await invalidateQueries();
        deactivateUserMutation.reset();
      },
      onError() {
        enqueueSnackbar({
          variant: 'error',
          message: 'Error deactivating user.',
        });
      },
    });
  };

  const handleReactivateUser = () => {
    reactivateUserMutation.mutate(null, {
      async onSuccess() {
        await invalidateQueries();
        reactivateUserMutation.reset();
      },
      onError() {
        enqueueSnackbar({
          variant: 'error',
          message: 'Error reactivating user.',
        });
      },
    });
  };

  const isSyncing =
    (!deactivateUserMutation.isIdle && !deactivateUserMutation.isError) ||
    (!reactivateUserMutation.isIdle && !reactivateUserMutation.isError);

  return (
    <>
      <UserDeactivationModal
        open={deactivationModalOpen}
        onClose={() => setDeactivationModalOpen(false)}
        onConfirm={handleDeactivateUser}
      />
      <SyncButton
        onClick={() =>
          active ? setDeactivationModalOpen(true) : handleReactivateUser()
        }
        syncing={isSyncing}
        sx={{
          px: 2,
          py: 1,
          borderRadius: `${shape.borderRadius}px`,
          background: active ? palette.success.main : palette.error.dark,
          '&:disabled': { color: 'white' },
          '&:hover': {
            background: active ? palette.success.main : palette.error.dark,
          },
        }}
      >
        <Typography variant='h6' color='white' minWidth='fit-content'>
          {isSyncing ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>{active ? 'Deactivating...' : 'Reactivating...'}</>
          ) : (
            <>Click to {active ? 'deactivate' : 'reactivate'} user</>
          )}
        </Typography>
      </SyncButton>
    </>
  );
}

function UserDeactivationModal({
  open,
  onClose,
  onConfirm,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        [`& .${dialogClasses.paper}`]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minWidth: '700px',
          rowGap: 4,
          p: 4,
        },
      }}
    >
      <IconButton
        color='primary'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: theme.spacing(2),
          top: theme.spacing(2),
        }}
      >
        <Close />
      </IconButton>
      <TwoToneHeader
        icon={<NoAccountsOutlined color='primary' />}
        text={['deactivate user']}
        invert
        sx={{ alignSelf: 'start' }}
      />
      <Alert
        severity='error'
        variant='filled'
        icon={<ErrorOutline color='inherit' />}
      >
        Are you sure you want to deactivate this user?
      </Alert>
      <Stack direction='row' columnGap={3}>
        <Button onClick={onClose} size='large'>
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            onConfirm();
            onClose();
          }}
          size='large'
        >
          Confirm
        </Button>
      </Stack>
    </Dialog>
  );
}

function FacilitySearch(props: {
  search: string;
  setSearch: (search: string) => void;
}) {
  const { search, setSearch } = props;
  return (
    <TextField
      label='Search'
      fullWidth
      sx={{ maxWidth: '500px' }}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Search color='primary' />
          </InputAdornment>
        ),
        endAdornment: search ? (
          <InputAdornment position='end'>
            <IconButton onClick={() => setSearch('')}>
              <Close />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      value={search}
      onChange={(e) => setSearch(e.target.value)}
    />
  );
}

function FacilityOptionsGrid(props: PropsWithChildren) {
  const { children } = props;
  const theme = useTheme();
  return (
    <Box
      px={5}
      my={3}
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        [theme.breakpoints.up('sm')]: {
          gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.up('md')]: {
          gridTemplateColumns: 'repeat(3, 1fr)',
        },
        [theme.breakpoints.up('lg')]: {
          gridTemplateColumns: 'repeat(4, 1fr)',
        },
        [theme.breakpoints.up('xl')]: {
          gridTemplateColumns: 'repeat(5, 1fr)',
        },
      }}
    >
      {children}
    </Box>
  );
}

/* eslint-disable camelcase */
function formatCreateFormData(data: AddEditUserFormValues): UserPostArgs {
  return {
    ...removeEmpty({
      picture: data.picture.trim(),
      last_name: data.last_name.trim(),
      display_name: data.display_name.trim(),
      title: data.title.trim(),
    }),
    first_name: data.first_name.trim(),
    email: data.email.trim(),
    all_facility_access: data.all_facility_access,
    roles: extractTrue<UserRole['name']>(data.roles),
    facility_ids: extractTrue<number>(data.facility_ids),
  };
}

function formatUpdateFormData(
  data: Partial<AddEditUserFormValues>,
): UserPatchArgs {
  return removeNullish(
    removeEmpty({
      ...data,
      roles: data.roles ? extractTrue<UserRole['name']>(data.roles) : undefined,
      facility_ids: data.facility_ids
        ? extractTrue<number>(data.facility_ids)
        : undefined,
    }),
  );
}
/* eslint-enable camelcase */

function extractTrue<T extends number | string>(
  obj: Record<T, boolean>,
): Array<T> {
  return Object.entries(obj)
    .filter(([, v]) => v)
    .map(([k]) => k) as T[];
}
