/* eslint-disable @typescript-eslint/no-non-null-assertion */

import {
  ChoiceInputElement,
  ClientInputRequest,
  ClientInputResponse,
  Icon as InputRequestIcon,
  InputElement,
  InputResponseElement,
  InputType,
  SessionResult,
  SessionStatus,
  TextInputElement,
  TextType,
} from '@insidedesk/goldfinger';
import {
  BlurableInput,
  Confetti,
  formatISOToAmerican,
  formatTaxId,
  getCredentialStatusHighlight,
  HTTPError,
  LabelledCell,
  LabelledCellLayoutProps,
  Switch,
  TwoToneHeader,
  useClientAPIMutationFn,
  useFlags,
} from '@insidedesk/tuxedo';
import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import {
  Close,
  EmailOutlined,
  LockPersonOutlined,
  PhoneAndroid,
  PinOutlined,
  SvgIconComponent,
  TextsmsOutlined,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CredentialAddEditForm,
  LoadingSpinner,
  PatchCredential,
} from 'components';
import { useCollectorDetails, useCredentialQuery } from 'hooks';
import { useCallback, useEffect, useReducer, useState } from 'react';
import {
  FormContainer,
  PasswordElement,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui';
import { useNavigate, useParams } from 'react-router-dom';
import { CredentialPatchParams, RedactedCredential } from 'types';
import { getCredentialStatusLabel, getURL } from 'utils';
import { useGoldfingerSession } from './hooks';

import './CredentialEditModal.scss';

const CREDENTIAL_LOADING_MESSAGE = 'Loading Credential';

interface DisplayState {
  componentName: string;
  goldfingerStatus: string | null;
  credential: RedactedCredential | null;
}

interface LoadingCredentialState extends DisplayState {
  componentName: 'loadingCredential';
  channelID: null;
  editError: null;
  credential: null;
  goldfingerStatus: null;
  elements: null;
  onSubmit: null;
}

interface DisplayEditFormState extends DisplayState {
  componentName: 'form';
  channelID: null;
  editError: Error | null;
  goldfingerStatus: null;
  elements: null;
  onSubmit: null;
}

interface DisplaySubmittingFormState extends DisplayState {
  componentName: 'submittingForm';
  editError: null;
  channelID: null;
  goldfingerStatus: null;
  elements: null;
  onSubmit: null;
}

interface DisplayGoldfingerStatusState extends DisplayState {
  componentName: 'goldfingerStatus';
  editError: null;
  channelID: string;
  goldfingerStatus: string;
  elements: null;
  onSubmit: null;
}

interface InputRequestState extends DisplayState {
  componentName: 'inputRequest';
  editError: null;
  channelID: string;
  goldfingerStatus: null;
  elements: InputElement[];
  onSubmit: (values: InputResponseElement[]) => void;
  prompt: string;
}

const inputIconMapping: Record<
  keyof typeof InputRequestIcon,
  SvgIconComponent
> = {
  [InputRequestIcon.EMAIL]: EmailOutlined,
  [InputRequestIcon.MFA_CODE]: PinOutlined,
  [InputRequestIcon.OTHER]: PinOutlined,
  [InputRequestIcon.PASSWORD]: PinOutlined,
  [InputRequestIcon.SMS]: TextsmsOutlined,
  [InputRequestIcon.TELEPHONE]: PhoneAndroid,
  [InputRequestIcon.USERNAME]: PinOutlined,
};

type ValidationCompleteStatus = SessionStatus | 'HEARTBEAT_FAILURE' | null;

interface ValidationCompleteState extends DisplayState {
  componentName: 'validationComplete';
  channelID: string | null;
  goldfingerStatus: null;
  messages: string[];
  status: ValidationCompleteStatus;
}

type State =
  | LoadingCredentialState
  | DisplayEditFormState
  | DisplayGoldfingerStatusState
  | DisplaySubmittingFormState
  | InputRequestState
  | ValidationCompleteState;

type Action =
  | {
      type: 'displayForm';
      payload?: { editError?: Error; credential?: RedactedCredential };
    }
  | { type: 'submittingForm' }
  | { type: 'goldfingerConnecting'; payload: { channelID: string } }
  | { type: 'goldfingerStatus'; payload: { status: string } }
  | {
      type: 'inputRequest';
      payload: {
        elements: InputElement[];
        onSubmit: (values: InputResponseElement[]) => void;
        prompt: string;
      };
    }
  | {
      type: 'validationComplete';
      payload: {
        status?: ValidationCompleteStatus;
        messages: string[];
      };
    };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'displayForm': {
      const editError = action.payload?.editError ?? null;
      return {
        ...state,
        goldfingerStatus: null,
        channelID: null,
        componentName: 'form',
        credential: action.payload?.credential ?? state.credential,
        editError,
        elements: null,
        onSubmit: null,
      };
    }
    case 'submittingForm':
      return {
        ...state,
        goldfingerStatus: null,
        channelID: null,
        editError: null,
        componentName: 'submittingForm',
        elements: null,
        onSubmit: null,
      };
    case 'goldfingerConnecting':
      return {
        ...state,
        editError: null,
        componentName: 'goldfingerStatus',
        channelID: action.payload.channelID,
        goldfingerStatus:
          'Connecting to insurance portal. This may take a few minutes.',
        elements: null,
        onSubmit: null,
      };
    case 'goldfingerStatus':
      if (state.channelID === null) {
        throw new Error('Cannot set goldfinger status without a session');
      }
      return {
        ...state,
        editError: null,
        componentName: 'goldfingerStatus',
        channelID: state.channelID,
        goldfingerStatus: action.payload.status,
        elements: null,
        onSubmit: null,
      };
    case 'inputRequest':
      if (state.channelID === null) {
        throw new Error('Cannot handle input request without a session');
      }
      return {
        ...state,
        editError: null,
        componentName: 'inputRequest',
        channelID: state.channelID,
        goldfingerStatus: null,
        elements: action.payload.elements,
        onSubmit: action.payload.onSubmit,
        prompt: action.payload.prompt,
      };
    case 'validationComplete':
      return {
        componentName: 'validationComplete',
        channelID: state?.channelID ?? null,
        credential: state.credential,
        goldfingerStatus: null,
        messages: action.payload.messages,
        status: action.payload.status ?? null,
      };
    default:
      throw new Error('Invalid action provided');
  }
}

export default function CredentialEditModal() {
  const navigate = useNavigate();
  const { credentialID } = useParams();
  const flags = useFlags();
  const { data: credential, isLoading } = useCredentialQuery(
    parseInt(credentialID!, 10),
  );
  const [open, setOpen] = useState(true);
  const handleClose = useCallback(() => setOpen(false), []);
  const handleExited = useCallback(() => navigate('..'), [navigate]);

  const [state, dispatch] = useReducer(reducer, {
    editError: null,
    channelID: null,
    componentName: 'loadingCredential',
    credential: null,
    goldfingerStatus: null,
    elements: null,
    onSubmit: null,
  });

  const submitCredential = useMutation<
    { goldfinger_channel_name: string | null },
    HTTPError,
    CredentialPatchParams
  >(
    useClientAPIMutationFn<
      { goldfinger_channel_name: string | null },
      CredentialPatchParams
    >(`admin/credentials/${state.credential?.id}`, {
      method: 'PATCH',
    }),
  );

  useEffect(() => {
    if (!isLoading && credential && !state.credential) {
      dispatch({ type: 'displayForm', payload: { credential } });
    }
  }, [credential, isLoading, state.credential]);

  const queryClient = useQueryClient();

  const handleCredentialSubmit = useCallback(
    (newCredential: CredentialPatchParams) => {
      dispatch({ type: 'submittingForm' });
      submitCredential.mutate(newCredential, {
        onSuccess: (data) => {
          queryClient.invalidateQueries({
            queryKey: ['credentialList'],
            refetchType: 'all',
          });
          queryClient.invalidateQueries({
            queryKey: ['credential', credential?.id],
          });
          if (data.goldfinger_channel_name !== null) {
            dispatch({
              type: 'goldfingerConnecting',
              payload: { channelID: data.goldfinger_channel_name },
            });
          } else {
            dispatch({
              type: 'validationComplete',
              payload: {
                messages: [
                  'This credential is in queue for validation.',
                  'Please check back later to confirm there are no issues.',
                ],
              },
            });
          }
        },
        onError: (err: HTTPError) => {
          const submitError = new Error(
            `${err.response.statusText} (${err.response.status})`,
          );
          dispatch({
            type: 'displayForm',
            payload: { editError: submitError },
          });
        },
      });
    },
    [submitCredential, queryClient, credential?.id],
  );

  useGoldfingerSession(state.channelID, {
    onStatus: useCallback((msg) => {
      dispatch({ type: 'goldfingerStatus', payload: { status: msg } });
    }, []),
    onInputRequest: useCallback(
      (request: ClientInputRequest) =>
        new Promise((resolve) => {
          function onSubmit(values: InputResponseElement[]) {
            resolve(new ClientInputResponse(values));
            dispatch({
              type: 'goldfingerStatus',
              payload: { status: 'Submitting' },
            });
          }

          dispatch({
            type: 'inputRequest',
            payload: {
              elements: request.elements,
              onSubmit,
              prompt: request.prompt,
            },
          });
        }),
      [],
    ),
    onSessionComplete: useCallback((result: SessionResult) => {
      dispatch({
        type: 'validationComplete',
        payload: {
          status: result.status,
          messages: [result.message],
        },
      });
    }, []),
    onHeartbeatFailure: useCallback(() => {
      dispatch({
        type: 'validationComplete',
        payload: {
          status: 'HEARTBEAT_FAILURE',
          messages: [
            'Unable to connect to portal. The portal might be down, please try again later.',
          ],
        },
      });
    }, []),
  });

  return (
    <Dialog
      id='credential-edit-modal'
      open={open}
      onClose={handleClose}
      fullWidth={state.componentName === 'form'}
      maxWidth={flags.credentialListIssuesExplanation ? 'lg' : 'md'}
      aria-labelledby='credential-edit-modal-title'
      aria-describedby='credential-edit-modal-description'
      PaperProps={{
        sx: {
          backgroundImage:
            state.componentName === 'validationComplete' &&
            state.status === SessionStatus.SUCCESS
              ? `url(${Confetti})`
              : undefined,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        },
      }}
      TransitionProps={{ onExited: handleExited }}
    >
      <DialogTitle id='credential-edit-modal-title'>
        <Grid container sx={{ alignItems: 'center', width: '100%' }}>
          <Grid item sx={{ pr: '10px' }}>
            <TwoToneHeader
              text={[
                '',
                state.componentName === 'form'
                  ? 'Edit Insurer Credentials'
                  : 'Validate Your Identity',
              ]}
              icon={<LockPersonOutlined />}
            />
          </Grid>
          <Grid
            item
            sx={{ display: 'flex', ml: 'auto', mr: '-20px', mt: '-30px' }}
          >
            <IconButton onClick={handleClose} role='button' aria-label='close'>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Switch
        expression={state.componentName}
        cases={[
          {
            value: 'loadingCredential',
            component: <LoadingIndicator text={CREDENTIAL_LOADING_MESSAGE} />,
          },
          {
            value: 'form',
            component: (
              <CredentialEditFormWrapper
                credential={state.credential!}
                editError={(state as DisplayEditFormState).editError}
                patchCredential={handleCredentialSubmit}
              />
            ),
          },
          {
            value: 'submittingForm',
            component: <LoadingIndicator text='Saving updated credential' />,
          },
          {
            value: 'goldfingerStatus',
            component: (
              <LoadingIndicator text={state.goldfingerStatus ?? undefined} />
            ),
          },
          {
            value: 'inputRequest',
            component: (
              <GoldfingerInputForm
                elements={(state as InputRequestState).elements ?? []}
                onSubmit={(state as InputRequestState).onSubmit}
                prompt={(state as InputRequestState).prompt}
              />
            ),
          },
          {
            value: 'validationComplete',
            component: (
              <ValidationComplete
                messages={(state as ValidationCompleteState).messages}
                onContinue={handleClose}
                onRetry={() => dispatch({ type: 'displayForm' })}
                status={(state as ValidationCompleteState).status}
              />
            ),
          },
        ]}
      />
    </Dialog>
  );
}

function CredentialEditFormWrapper(props: {
  credential: RedactedCredential;
  editError: Error | null;
  patchCredential: PatchCredential;
}) {
  const { credential, editError, patchCredential } = props;
  const flags = useFlags();
  const { data: collectorDetails, isInitialLoading } = useCollectorDetails(
    credential.collector_id,
  );

  const commonLabelCellProps: LabelledCellLayoutProps = {
    labelProps: {
      variant: 'subtitle1',
      whiteSpace: 'nowrap',
    },
    valueProps: {
      sx: { fontWeight: 600 },
      variant: 'body1',
      whiteSpace: 'nowrap',
    },
  };

  /**
   * Keep loading message consistent with credential loading message. User
   * doesn't need to know that we are loading collector details.
   */
  if (isInitialLoading) {
    return <LoadingIndicator text={CREDENTIAL_LOADING_MESSAGE} />;
  }

  return (
    <DialogContent>
      {editError ? (
        <Alert severity='error' variant='filled'>
          There was an error editing this credential: {editError.message}
        </Alert>
      ) : null}
      <Stack
        direction='row'
        sx={{ alignItems: 'end', justifyContent: 'space-between' }}
      >
        <LabelledCell
          label='Insurer'
          value={credential.payer ?? ''}
          {...commonLabelCellProps}
        />
        <LabelledCell
          label='Tax Id'
          value={formatTaxId(credential.tax_id)}
          phi
          {...commonLabelCellProps}
        />
        <LabelledCell
          label='Portal'
          value={
            <Link
              target='_blank'
              href={credential.payer_website}
              rel='noopener noreferrer'
            >
              {getURL(credential.payer_website)}
            </Link>
          }
          {...commonLabelCellProps}
        />
        {flags.removeCredentialLastAccessedField ? null : (
          <LabelledCell
            label='Last Accessed'
            value={
              credential.last_accessed
                ? formatISOToAmerican(credential.last_accessed)
                : 'Never'
            }
            {...commonLabelCellProps}
          />
        )}
        <LabelledCell
          label='Status'
          value={
            <Tooltip
              title={
                flags.credentialListIssuesExplanation
                  ? credential?.latest_invalid_login_status_code?.explanation
                  : undefined
              }
              placement='top'
              arrow
            >
              <Box color={getCredentialStatusHighlight(credential.status)}>
                {(flags.credentialListIssuesExplanation &&
                  credential?.latest_invalid_login_status_code?.title) ||
                  getCredentialStatusLabel(credential.status)}
              </Box>
            </Tooltip>
          }
          {...commonLabelCellProps}
        />
      </Stack>
      <CredentialAddEditForm
        credential={credential}
        collectorDetails={collectorDetails}
        patchCredential={patchCredential}
      />
    </DialogContent>
  );
}

function LoadingIndicator({ text }: { text?: string }) {
  return (
    <DialogContent
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card variant='outlined' sx={{ maxWidth: '250px' }}>
        <CardContent>
          <LoadingSpinner
            description={text}
            imgStyleProps={{ width: '100%' }}
          />
        </CardContent>
      </Card>
    </DialogContent>
  );
}

function GoldfingerInputForm(props: {
  elements: InputElement[];
  onSubmit: (values: InputResponseElement[]) => void;
  prompt: string;
}) {
  const { elements, onSubmit, prompt } = props;
  const defaultValues = Object.fromEntries(
    elements.map((element) => [element.id, element.defaultValue]),
  );
  const formContext = useForm<typeof defaultValues>(defaultValues);

  const handleSubmit = useCallback(
    (values: typeof defaultValues) => {
      const responseElements = Object.entries(values)
        .filter((entry): entry is [string, string] => entry[1] !== undefined)
        .map(([id, value]) => new InputResponseElement(id, value));
      onSubmit(responseElements);
    },
    [onSubmit],
  );

  const isAnyRequired =
    elements
      .filter(
        (element): element is TextInputElement =>
          element.type === InputType.TEXT,
      )
      .filter((element) => element.validation.required).length > 0;

  return (
    <div id='goldfinger-input-form'>
      <FormContainer formContext={formContext} onSuccess={handleSubmit}>
        <DialogContent data-testid='text-input-request'>
          <Stack
            direction='column'
            spacing={6}
            justifyContent='space-between'
            alignItems='center'
            height='100%'
          >
            <Alert
              variant='filled'
              /** We use the same icon as the error alert here so use the
               * severity to ensure the sizing is consistent. Can override the
               * color separately */
              severity='error'
              color='success'
              aria-label={prompt}
            >
              {prompt}
            </Alert>
            {elements.map((element, index) => {
              if (element instanceof TextInputElement) {
                const Icon = element.icon
                  ? inputIconMapping[element.icon]
                  : null;
                const required = isAnyRequired
                  ? !!element.validation.required
                  : true;
                if (element.textType === TextType.PASSWORD) {
                  return (
                    <PasswordElement
                      autoFocus={index === 0}
                      key={element.id}
                      label={element.text}
                      name={element.id}
                      required={required}
                    />
                  );
                }
                return (
                  <TextFieldElement
                    autoFocus={index === 0}
                    key={element.id}
                    label={element.text}
                    name={element.id}
                    required={required}
                    InputProps={{
                      startAdornment:
                        Icon !== null ? (
                          <Icon color='primary' sx={{ marginRight: '10px' }} />
                        ) : undefined,
                      inputComponent: BlurableInput,
                    }}
                  />
                );
              }
              if (element instanceof ChoiceInputElement) {
                return (
                  <FormControl key={element.id} required>
                    <RadioGroup aria-labelledby={element.id}>
                      {element.options.map((option) => {
                        const Icon = option.icon
                          ? inputIconMapping[option.icon]
                          : null;
                        return (
                          <FormControlLabel
                            {...formContext.register(element.id, {
                              required: true,
                            })}
                            key={option.id}
                            value={option.id}
                            control={<Radio />}
                            label={
                              <Box
                                sx={{ display: 'flex', alignContent: 'center' }}
                              >
                                <>
                                  {Icon ? (
                                    <Icon
                                      color='primary'
                                      sx={{ marginRight: '10px' }}
                                    />
                                  ) : null}
                                  {option.text}
                                </>
                              </Box>
                            }
                            labelPlacement='start'
                            sx={{
                              justifyContent: 'space-between',
                              px: '18px',
                              py: '6px',
                              m: '12px',
                            }}
                            slotProps={{
                              typography: {},
                            }}
                            selected={
                              formContext.watch(element.id) === option.id
                            }
                            variant='outline'
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                );
              }
              /* Don't want this to fail completely silently so that collector
               * who might add an element type know why they aren't seeing it.
               */
              // eslint-disable-next-line no-console
              console.log(
                'Invalid Element provided, only Text and Choices allowed.',
              );
              return null;
            })}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            type='submit'
            name='submit'
            color='primary'
            disabled={!formContext.formState.isValid}
            variant='contained'
          >
            Continue
          </Button>
        </DialogActions>
      </FormContainer>
    </div>
  );
}

function ValidationComplete(props: {
  messages: string[];
  onContinue: () => void;
  onRetry: () => void;
  status: ValidationCompleteStatus;
}) {
  const { status, onContinue, onRetry, messages } = props;

  let body = null;
  if (status === SessionStatus.SUCCESS || status === null) {
    body = (
      <Card
        variant='outlined'
        sx={{
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.05)',
          border: variables.borderLight,
        }}
      >
        <CardContent
          sx={{ display: 'grid', placeContent: 'center', minHeight: '320px' }}
        >
          <Stack spacing={2}>
            <Typography
              sx={{
                fontSize: '1.5rem',
                color: status ? 'success.main' : 'primary.main',
                fontWeight: 600,
                textAlign: 'center',
              }}
            >
              {status ? 'Success' : 'In Progress...'}
            </Typography>
            {messages.map((message) => (
              <Typography
                key={message}
                sx={{ textAlign: 'center', fontWeight: 600 }}
              >
                {message}
              </Typography>
            ))}
          </Stack>
        </CardContent>
      </Card>
    );
  } else {
    body = (
      <Stack width='100%'>
        {messages.map((message) => (
          <Alert
            key={message}
            severity='error'
            variant='filled'
            aria-label={message}
            sx={{ width: '90%', margin: 'auto' }}
          >
            {message}
          </Alert>
        ))}
      </Stack>
    );
  }
  return (
    <>
      <DialogContent
        sx={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}
      >
        {body}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', margin: '10px' }}>
        <Button
          variant='contained'
          color='primary'
          onClick={status !== SessionStatus.ERROR ? onContinue : onRetry}
        >
          {status !== SessionStatus.ERROR ? 'Continue' : 'Try Again'}
        </Button>
      </DialogActions>
    </>
  );
}
