import {
  CenterInPage,
  ErrorBoundary,
  FlagLoadGuard,
  LoadingSpinner,
} from '@insidedesk/tuxedo';
import { Box } from '@mui/material';
import {
  AddUserPage,
  CredentialEditModal,
  CredentialPage,
  EditUserPage,
  UserPage,
} from 'components';
import { NavbarLayout } from 'layouts';
import { PropsWithChildren } from 'react';
import { Outlet, redirect, RouteObject } from 'react-router-dom';
import ErrorElement from './ErrorElement';
import LayoutSelector from './LayoutSelector';
import PermissionGuard from './PermissionGuard';

function RootErrorLayout({ children }: PropsWithChildren) {
  return (
    <Box display='flex' flexDirection='column' width='100%' height='100%'>
      {children}
    </Box>
  );
}

const routes: RouteObject[] = [
  {
    element: (
      <FlagLoadGuard
        fallback={
          <CenterInPage>
            <LoadingSpinner />
          </CenterInPage>
        }
      >
        <NavbarLayout>
          <ErrorBoundary fallbackComponent={ErrorElement}>
            <PermissionGuard>
              <LayoutSelector>
                <Outlet />
              </LayoutSelector>
            </PermissionGuard>
          </ErrorBoundary>
        </NavbarLayout>
      </FlagLoadGuard>
    ),
    errorElement: (
      <RootErrorLayout>
        <ErrorElement />
      </RootErrorLayout>
    ),
    children: [
      {
        path: 'credentials',
        element: <CredentialPage />,
        handle: { layout: 'list', permission: 'read:credentials' },
        children: [
          {
            path: ':credentialID',
            element: <CredentialEditModal />,
            handle: {
              layout: 'list',
              permission: 'write:credentials',
            },
          },
        ],
      },
      {
        path: 'users',
        handle: { breadcrumb: 'Users', permission: 'read:users' },
        children: [
          {
            index: true,
            element: <UserPage />,
            handle: { layout: 'list' },
          },
          {
            path: 'add',
            element: <AddUserPage />,
            handle: {
              layout: 'detail',
              permission: 'write:users',
              breadcrumb: 'Add User',
              title: ['Add ', 'User'],
            },
          },
          {
            path: ':id/edit',
            element: <EditUserPage />,
            handle: {
              layout: 'detail',
              permission: 'write:users',
              breadcrumb: 'Edit User',
              title: ['Edit ', 'User'],
            },
          },
        ],
      },
      {
        path: '/',
        loader: () => redirect('/credentials'),
      },
      {
        path: '/insurer-credentials',
        loader: () => redirect('/credentials'),
      },
      // Make sure 404 errors are rendered within the navbar layout
      {
        path: '*',
        element: <ErrorElement error={new Response(null, { status: 404 })} />,
      },
    ],
  },
];
export default routes;
