import { useClientAPIQueryFn, useHasPermission } from '@insidedesk/tuxedo';
import { useQuery } from '@tanstack/react-query';
import { UserRole } from 'types';

export default function useUserRoles() {
  const { hasPermission } = useHasPermission();
  return useQuery(
    ['user-roles'],
    useClientAPIQueryFn<UserRole[]>('users/user-roles'),
    {
      enabled: hasPermission('read:users'),
      suspense: true,
      useErrorBoundary: true,
    },
  );
}
