import {
  formatISOToAmerican,
  PHI,
  SyncButton,
  useHasPermission,
  UserAvatar,
  useSnackbar,
} from '@insidedesk/tuxedo';
import { Edit, MarkEmailReadOutlined } from '@mui/icons-material';
import {
  alpha,
  Button,
  Card,
  Grid,
  Link as MuiLink,
  styled,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { useResendWelcomeEmail } from 'hooks';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { User } from 'types';

const hideTextOverflow = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

const STYLED_CARD_HEIGHT = '96px';

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'deactivated',
})<{ deactivated?: boolean }>(({ theme, deactivated }) => ({
  height: STYLED_CARD_HEIGHT,
  borderColor: theme.palette.divider,
  backgroundColor: deactivated ? theme.palette.grey[100] : '',
}));

const GridContainer = styled(Grid)(({ theme }) => ({
  height: '100%',
  alignItems: 'center',
  columnGap: theme.spacing(2),
  flexWrap: 'nowrap',
}));

interface UserListItemProps {
  user: User;
  index: number;
}

export default function UserListItem(props: UserListItemProps) {
  const { user, index } = props;
  const { hasPermission } = useHasPermission();
  const theme = useTheme();
  const fullName = user.last_name
    ? `${user.first_name} ${user.last_name}`
    : user.first_name;
  const hasDifferentNames = user.display_name && user.display_name !== fullName;

  return (
    <StyledCard
      variant='outlined'
      deactivated={!user.active}
      data-testid='user-list-item'
    >
      <GridContainer container>
        <Grid item>
          <UserStatus status={user.status} />
        </Grid>
        <Grid item>
          <PHI>
            <UserAvatar
              user={user}
              variant='square'
              sx={{
                height: '64px',
                width: '64px',
                borderRadius: '12px',
                background: getAvatarBackgroundColor(user, index, theme),
              }}
            />
          </PHI>
        </Grid>
        <Grid item xs={2}>
          <Typography
            color='primary'
            fontSize='1.125rem'
            fontWeight={500}
            sx={hideTextOverflow}
          >
            <EmailLink email={user.email ?? ''}>
              <PHI>{user.display_name || fullName}</PHI>
            </EmailLink>
          </Typography>
          {hasDifferentNames && (
            <Typography variant='caption' fontWeight={500}>
              Display Name
            </Typography>
          )}
        </Grid>
        {hasDifferentNames && (
          <Grid item xs={2}>
            <>
              <Typography fontSize='1.125rem' fontWeight={400}>
                <PHI>{fullName}</PHI>
              </Typography>
              <Typography variant='caption' fontWeight={500}>
                Name
              </Typography>
            </>
          </Grid>
        )}
        <Grid item xs={3} flexDirection='column'>
          <Typography fontWeight={400}>{user.user_metadata?.title}</Typography>
          <Typography
            color='primary'
            fontWeight={600}
            overflow='hidden'
            textOverflow='ellipsis'
            whiteSpace='nowrap'
          >
            <EmailLink email={user.email ?? ''}>
              <PHI>{user.email}</PHI>
            </EmailLink>
          </Typography>
        </Grid>
        <Grid item xs={2} display='flex' justifyContent='end' ml='auto'>
          {user.status === 'invited' && hasPermission('write:users') ? (
            <ResendInviteButton user={user} />
          ) : (
            <Typography variant='caption' textAlign='right'>
              Last Login:{' '}
              {user.last_login ? formatISOToAmerican(user.last_login) : 'Never'}
            </Typography>
          )}
        </Grid>
        <Grid item>
          {hasPermission('write:users') && <EditButton user={user} />}
        </Grid>
      </GridContainer>
    </StyledCard>
  );
}

function UserStatus({ status }: { status: User['status'] }) {
  const theme = useTheme();

  const statusColorMapping: Record<User['status'], string> = {
    active: theme.palette.success.main,
    inactive: theme.palette.error.main,
    deactivated: theme.palette.grey[500],
    invited: theme.palette.warning.dark,
  };

  return (
    <Typography
      color={statusColorMapping[status]}
      sx={{
        width: `calc(${STYLED_CARD_HEIGHT} - 1px)`,
        maxWidth: `calc(${STYLED_CARD_HEIGHT} - 1px)`,
        textAlign: 'center',
        fontWeight: 700,
        fontSize: status === 'deactivated' ? '0.7rem' : '0.875rem',
        textTransform: 'uppercase',
        transform: 'rotate(-90deg)',
        ml: -3,
        mr: -4,
      }}
    >
      {status}
    </Typography>
  );
}

function EmailLink(props: PropsWithChildren<{ email: string }>) {
  const { email, children } = props;
  return (
    <MuiLink href={`mailto:${email}`} underline='none'>
      {children}
    </MuiLink>
  );
}

function ResendInviteButton(props: { user: User }) {
  const { user } = props;

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const welcomeEmailMutation = useResendWelcomeEmail();

  const handleResendWelcomeEmail = () => {
    welcomeEmailMutation.mutate(
      {
        name: user.name ?? '',
        email: user.email ?? '',
      },
      {
        onSuccess() {
          enqueueSnackbar({
            icon: <MarkEmailReadOutlined />,
            variant: 'success',
            message: 'Invite resent.',
          });
        },
        onError() {
          enqueueSnackbar({
            variant: 'error',
            message: 'Failed to resend invite.',
          });
        },
      },
    );
  };

  return (
    <SyncButton
      syncing={welcomeEmailMutation.isLoading}
      variant='outlined'
      color='inherit'
      size='small'
      sx={{
        fontWeight: 500,
        fontSize: '0.8125rem',
        background: alpha(theme.palette.warning.main, 0.05),
        border: `1px solid ${theme.palette.warning.dark}`,
        '&:hover': {
          background: theme.palette.warning.dark,
          color: 'white',
        },
      }}
      onClick={handleResendWelcomeEmail}
    >
      {welcomeEmailMutation.isLoading ? 'Sending' : 'Resend Invite'}
    </SyncButton>
  );
}

function EditButton(props: { user: User }) {
  const { user } = props;
  const { palette } = useTheme();
  return (
    <Button
      component={Link}
      to={`${user.id}/edit`}
      variant='outlined'
      color='inherit'
      size='small'
      startIcon={<Edit color='primary' />}
      sx={{
        mr: 2,
        fontWeight: 400,
        fontSize: '0.75rem',
        textTransform: 'uppercase',
        '&:hover': {
          background: palette.secondary.main,
          color: 'white ',
          '& svg': { color: 'white' },
        },
      }}
    >
      Edit
    </Button>
  );
}

function getAvatarBackgroundColor(user: User, index: number, theme: Theme) {
  if (user.picture && !user.picture.includes('auth0.com')) {
    return 'none';
  }

  const colors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.secondary.light,
    theme.palette.success.main,
  ];
  return colors[index % colors.length];
}
