import {
  getCredentialStatusHighlight,
  useFlagStatus,
} from '@insidedesk/tuxedo';
import { Chip, ChipProps, Tooltip } from '@mui/material';
import { RedactedCredential } from 'types';
import { getCredentialStatusLabel } from 'utils';

export interface CredentialStatusChipProps extends ChipProps {
  credential: RedactedCredential;
}

export function CredentialStatusChip(props: CredentialStatusChipProps) {
  const { credential, ...rest } = props;

  const credentialListIssuesExplanationEnabled = useFlagStatus(
    'credentialListIssuesExplanation',
  );
  const color = getCredentialStatusHighlight(credential.status);
  let issueStatusTitle: string | undefined;
  let issuesStatusExplanation: string | undefined;
  if (
    credentialListIssuesExplanationEnabled &&
    credential.status === 'issues'
  ) {
    issueStatusTitle = credential.latest_invalid_login_status_code?.title;
    issuesStatusExplanation =
      credential.latest_invalid_login_status_code?.explanation;
  }
  const label = issueStatusTitle || getCredentialStatusLabel(credential.status);

  return (
    <Tooltip title={issuesStatusExplanation} placement='top' arrow>
      <Chip
        label={label}
        {...rest}
        sx={{ ...rest.sx, color: 'white', backgroundColor: color }}
      />
    </Tooltip>
  );
}
