import { Auth0Provider } from '@auth0/auth0-react';
import { defaultTheme } from '@insidedesk/tuxedo';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import App from 'App';
import { AUTH0_CONFIG } from 'config';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

const customTheme = createTheme(defaultTheme);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={customTheme}>
      <Auth0Provider
        {...AUTH0_CONFIG}
        redirectUri={window.location.origin}
        useRefreshTokens
      >
        <CssBaseline />
        <App />
      </Auth0Provider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
