import {
  BlurableTextField,
  Flag,
  LoadingSpinner,
  PHI,
  TwoToneHeader,
  useClientAPIQueryFn,
  useFlags,
  useHasPermission,
  useHasRole,
} from '@insidedesk/tuxedo';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  Button,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TablePagination,
  Typography,
  useTheme,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CredentialAddModal, CredentialList } from 'components';
import { useCredentialsQuery } from 'hooks';
import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import {
  collectorTypeAtom,
  pageAtom,
  rowsPerPageAtom,
  sortAtom,
  taxIdAtom,
} from 'state/credential-page';
import { CollectorType, RedactedCredentialFilterOptionResult } from 'types';
import { COLLECTOR_TYPE_OPTIONS } from '../../../constants';
import Sorting from '../Sorting';
import './CredentialPage.scss';

export default function CredentialPage() {
  const [taxId, setTaxId] = useAtom(taxIdAtom);
  const [credentialAddModalOpen, setCredentialAddModalOpen] = useState(false);
  const flags = useFlags();
  const { hasPermission } = useHasPermission();
  const hasRole = useHasRole();
  const { palette } = useTheme();
  const resetSort = useResetAtom(sortAtom);

  const credentialFilterOptionsQuery =
    useQuery<RedactedCredentialFilterOptionResult>(
      ['credentialFilters'],
      useClientAPIQueryFn<RedactedCredentialFilterOptionResult>(
        'admin/credentials/filters',
      ),
      { useErrorBoundary: true },
    );

  const allowEmptyTaxId =
    flags.allowEmptyTaxIdForCredentials ||
    (!flags.allowEmptyTaxIdForCredentials && taxId !== '');

  useEffect(() => {
    credentialFilterOptionsQuery.data?.tax_ids.sort();
  }, [credentialFilterOptionsQuery.data?.tax_ids]);

  useEffect(() => {
    resetSort();
    // Only running this hook on initial render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    credentialFilterOptionsQuery.data?.tax_ids.sort();
  }, [credentialFilterOptionsQuery.data?.tax_ids]);

  return (
    <Box
      data-testid='credential-page'
      className='flex-scroll-parent'
      flexDirection='column'
      mx={5}
      pt={1}
    >
      {credentialAddModalOpen && (
        <CredentialAddModal
          open={credentialAddModalOpen}
          onClose={() => setCredentialAddModalOpen(false)}
          taxIds={credentialFilterOptionsQuery.data?.tax_ids || []}
        />
      )}
      <Stack
        direction='row'
        spacing={2}
        alignItems='center'
        justifyContent='space-between'
      >
        <Typography variant='h5' component='h1'>
          Credentials List
        </Typography>
        <Stack direction='row' spacing={2}>
          {hasRole('internal') && hasPermission('read:credentials') && (
            <CollectorTypeSelect />
          )}
          <TaxIdSelect
            options={credentialFilterOptionsQuery.data?.tax_ids || []}
            value={taxId}
            onChange={(_, newValue) => setTaxId(newValue ?? '')}
          />
          {hasRole('internal') &&
            hasPermission('write:credentials') &&
            flags.addCredentialViaSettings && (
              <Button
                variant='contained'
                sx={{
                  textTransform: 'uppercase',
                  height: '100%',
                  '&:hover': {
                    backgroundColor: palette.secondary.main,
                    color: 'white',
                  },
                }}
                onClick={() => setCredentialAddModalOpen(true)}
                disabled={credentialFilterOptionsQuery.isInitialLoading}
              >
                Add credentials
              </Button>
            )}
        </Stack>
      </Stack>
      <Flag flagName='credentialListSorting'>
        <Sorting />
      </Flag>
      {allowEmptyTaxId ? <CredentialPageContent /> : <PleaseSelectATaxId />}
    </Box>
  );
}

function CredentialPageContent() {
  const [page, setPage] = useAtom(pageAtom);
  const [rowsPerPage, setRowsPerPage] = useAtom(rowsPerPageAtom);
  const { data, isFetching, isFetched, isInitialLoading } =
    useCredentialsQuery();
  const showProgress = isFetching && !isFetched && !isInitialLoading;
  const { hasPermission } = useHasPermission();

  return (
    <>
      <LinearProgress
        data-testid='credentials-linear-progress'
        color='secondary'
        sx={{
          visibility: showProgress ? 'visible' : 'hidden',
          minHeight: '6px',
          mb: 1,
        }}
      />
      {isInitialLoading ? (
        <LoadingSpinner />
      ) : (
        <CredentialList credentials={data?.data.page || []} />
      )}
      <TablePagination
        className='flex-scroll-sibling'
        component='div'
        count={data?.data.totalItems || 0}
        page={page}
        onPageChange={(_, page_) => setPage(page_)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(e) =>
          setRowsPerPage(parseInt(e.target.value, 10))
        }
      />
      {hasPermission('write:credentials') && <Outlet />}
    </>
  );
}

function CollectorTypeSelect() {
  const [collectorType, setCollectorType] = useAtom(collectorTypeAtom);

  return (
    <FormControl>
      <InputLabel id='collector-type-select-label'>Collector Type</InputLabel>
      <Select
        id='collector-type-select'
        size='small'
        value={collectorType}
        labelId='collector-type-select-label'
        label='Collector Type'
        aria-labelledby='collector-type-select-label'
        onChange={(e: SelectChangeEvent<CollectorType | 'all'>) =>
          setCollectorType(e.target.value as CollectorType)
        }
        sx={{ minWidth: '180px' }}
      >
        {COLLECTOR_TYPE_OPTIONS.map((option) => (
          <MenuItem key={option} value={option}>
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function TaxIdSelect(
  props: Omit<
    AutocompleteProps<string, false, false, true>,
    'renderInput' | 'renderOption'
  >,
) {
  return (
    <Autocomplete
      size='small'
      {...props}
      groupBy={(option) => option.split('-')[0]}
      renderInput={(params) => <BlurableTextField {...params} label='Tax Id' />}
      renderOption={(itemProps, option) => (
        <li {...itemProps}>
          <PHI>{option}</PHI>
        </li>
      )}
    />
  );
}

function PleaseSelectATaxId() {
  const headerProps = {
    fontSize: 48,
    fontWeight: 600,
  };
  return (
    <Stack alignItems='center' justifyContent='center' height='100%'>
      <Stack direction='row' alignItems='center'>
        <PersonOutlineIcon color='primary' sx={{ fontSize: 150 }} />
        <PriorityHighIcon color='primary' sx={{ fontSize: 100, ml: '-50px' }} />
      </Stack>
      <TwoToneHeader {...headerProps} text={['please select a ', 'taxid']} />
      <TwoToneHeader {...headerProps} text={['to load credentials']} />
    </Stack>
  );
}
