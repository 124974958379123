export default function formatTaxId(taxId?: string) {
  if (!taxId) {
    return '';
  }

  if (taxId.indexOf('-') !== 2) {
    // If the - in the tax id is in the wrong place, remove it
    // It will be inserted into the correct position in the next if block
    taxId.replaceAll('-', '');
  }

  if (!taxId.includes('-')) {
    return `${taxId.substring(0, 2)}-${taxId.substring(2)}`;
  }

  return taxId;
}
