import { CredentialStatus, SortDirection } from '@insidedesk/tuxedo';
import {
  CollectorType,
  CredentialSortAttribute,
  UserFilterBy,
  UserSort,
  UserSortBy,
} from 'types';

export const CREDENTIAL_DEFAULT_SORT_DIRECTION: Record<
  CredentialSortAttribute,
  SortDirection
> = {
  claim_count: 'desc',
  collector_name: 'asc',
  username: 'asc',
};

export const CREDENTIAL_DEFAULT_SORT = {
  attribute: 'claim_count',
  direction: CREDENTIAL_DEFAULT_SORT_DIRECTION.claim_count,
};

export const CREDENTIAL_SORT_ATTRIBUTE_LABELS: Record<
  CredentialSortAttribute,
  string
> = {
  claim_count: '# of Open Claims',
  collector_name: 'Insurer',
  username: 'Username',
};

export const USER_SORT_OPTIONS: UserSortBy[] = [
  'first_name',
  'last_name',
  'display_name',
  'email',
];

export const DEFAULT_USER_SORT: UserSort = {
  sortBy: 'first_name',
  order: 'asc',
};

export const DEFAULT_USER_FILTER: UserFilterBy = 'active';

export const DEFAULT_CREDENTIAL_FILTER: CredentialStatus = 'issues';
export const COLLECTOR_TYPE_OPTIONS: NonNullable<(CollectorType | 'all')[]> = [
  'all',
  'insurer',
  'clearinghouse',
  'pms',
];
