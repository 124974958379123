import {
  CredentialStatus,
  HTTPError,
  useClientAPIQueryFn,
  useFlags,
  useHasPermission,
} from '@insidedesk/tuxedo';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import {
  collectorTypeAtom,
  filterByAtom,
  pageAtom,
  rowsPerPageAtom,
  sortAtom,
  taxIdAtom,
} from 'state/credential-page';
import { CredentialListQueryParams, RedactedCredential } from 'types';
import { createSearchParams } from 'utils';

type StatusCounts = Record<CredentialStatus | '', number>;

export interface RedactedCredentialResult {
  data: {
    page: RedactedCredential[];
    statusTotals: StatusCounts;
    totalItems: number;
  };
}

export default function useCredentialsQuery(
  options: UseQueryOptions<
    unknown,
    HTTPError,
    RedactedCredentialResult,
    string[]
  > = {},
) {
  const { hasPermission } = useHasPermission();
  const flags = useFlags();
  const taxId = useAtomValue(taxIdAtom);
  const page = useAtomValue(pageAtom);
  const rowsPerPage = useAtomValue(rowsPerPageAtom);
  const collectorType = useAtomValue(collectorTypeAtom);
  const sort = useAtomValue(sortAtom);

  const queryParams: CredentialListQueryParams = {
    tax_id: taxId,
    status: useAtomValue(filterByAtom) || undefined,
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    collector_type: collectorType === 'all' ? undefined : collectorType,
  };

  if (flags.credentialListSorting) {
    queryParams.sort = [`${sort.attribute},${sort.direction}`];
  }

  const queryString = createSearchParams(queryParams).toString();

  const queryFn = useClientAPIQueryFn<RedactedCredentialResult>(
    `admin/credentials/?${queryString}`,
  );

  const allowEmpty =
    flags.allowEmptyTaxIdForCredentials ||
    (!flags.allowEmptyTaxIdForCredentials && taxId !== '');

  return useQuery<unknown, HTTPError, RedactedCredentialResult, string[]>(
    ['credentialList', queryString],
    queryFn,
    {
      keepPreviousData: taxId !== '',
      useErrorBoundary: true,
      ...options,
      enabled:
        allowEmpty &&
        hasPermission('read:credentials') &&
        (options?.enabled ?? true),
    },
  );
}
