import { useSnackbar } from '@insidedesk/tuxedo';
import { useQueryClient } from '@tanstack/react-query';
import { AddEditUserForm } from 'components';
import {
  useFacilitiesQuery,
  useUpdateUser,
  useUserQuery,
  useUserRoles,
} from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { UserPatchArgs } from 'types';

export default function EditUserPage() {
  const id = parseInt(useParams().id ?? '', 10);
  const queryClient = useQueryClient();
  const updateUserMutation = useUpdateUser(id);
  const navigate = useNavigate();
  const { data: user } = useUserQuery(id);
  const { data: roles } = useUserRoles();
  const { data: facilityOptions } = useFacilitiesQuery();
  const { enqueueSnackbar } = useSnackbar();

  const updateUser = (data: UserPatchArgs) => {
    updateUserMutation.mutate(data, {
      async onSuccess() {
        await queryClient.invalidateQueries(['user-list'], {
          type: 'all',
        });
        await queryClient.invalidateQueries(['user', id]);
        enqueueSnackbar({
          variant: 'success',
          message: 'User updated successfully!',
        });
        navigate('/users', { replace: true });
      },
      onError() {
        enqueueSnackbar({
          variant: 'error',
          message: 'Failed to update user.',
        });
      },
    });
  };

  if (roles && facilityOptions && user) {
    return (
      <AddEditUserForm
        user={user}
        roles={roles}
        facilityOptions={facilityOptions}
        updateUser={updateUser}
        isSubmitting={
          updateUserMutation.isLoading || updateUserMutation.isSuccess
        }
      />
    );
  }

  return null;
}
