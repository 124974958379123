import { useClientAPIMutationFn } from '@insidedesk/tuxedo';
import { useMutation } from '@tanstack/react-query';
import { ResendWelcomeEmailArgs } from 'types';

export default function useResendWelcomeEmail() {
  const mutationFn = useClientAPIMutationFn<void, ResendWelcomeEmailArgs>(
    'users/resend-welcome-email',
    { method: 'POST' },
  );

  return useMutation({ mutationFn });
}
