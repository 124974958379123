import { DetailLayout, ListLayout } from 'layouts';
import { PropsWithChildren } from 'react';
import { Outlet, useMatches } from 'react-router-dom';
import { RouteHandle } from 'types';

export default function LayoutSelector({ children }: PropsWithChildren) {
  const matches = useMatches();
  const leafMatch = matches[matches.length - 1];
  const handle = leafMatch.handle as RouteHandle | undefined;
  if (handle?.layout === 'list') {
    return <ListLayout>{children}</ListLayout>;
  }
  if (handle?.layout === 'detail') {
    return <DetailLayout>{children}</DetailLayout>;
  }
  return <Outlet />;
}
