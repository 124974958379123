import { useAuth0 } from '@auth0/auth0-react';
import {
  BrowserClientAuthConfig,
  EventType,
  GoldfingerPusherClient,
  GoldfingerWebSession,
  InputRequestCallback,
  SessionCompletionCallback,
  StatusMessageCallback,
} from '@insidedesk/goldfinger';
import { propertyIn } from '@insidedesk/tuxedo';
import { useEffect, useState } from 'react';

/**
 * Start a goldfinger session and assign the appropriate handlers.
 *
 * @param channelID channel to connect to
 * @param handlers Object containing goldfinger event handlers
 * @param handlers.onStatus Function to handle status messages
 * @param handlers.onInputRequest Function to handle input requests
 * @param handlers.onSessionComplete Function to handle completed session events
 * @param handlers.onHeartbeatFailure Handle losing connection to the collector. Session
 * is closed after callback runs.
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export function useGoldfingerSession(
  channelID: string | null,
  handlers: {
    onStatus: StatusMessageCallback;
    onInputRequest: InputRequestCallback;
    onSessionComplete: SessionCompletionCallback;
    onHeartbeatFailure: () => void;
  },
) {
  const { onStatus, onInputRequest, onSessionComplete, onHeartbeatFailure } =
    handlers;
  const { getAccessTokenSilently } = useAuth0();

  const [session, setSession] = useState<GoldfingerWebSession | undefined>();

  useEffect(() => {
    async function runGoldfingerSession(channel: string) {
      const pusherKey = process.env.REACT_APP_PUSHER_KEY;
      const pusherCluster = process.env.REACT_APP_PUSHER_CLUSTER;

      const client = new GoldfingerPusherClient(
        pusherKey || '',
        pusherCluster || 'us2',
        {
          heartbeatTimeout: 30 * 1000,
          waitOptions: {
            timeout: 5 * 60 * 1000,
            sessionCompleteTimeout: 5 * 1000,
          },
        },
        new BrowserClientAuthConfig(
          `${process.env.REACT_APP_CLIENT_API_URL}/pusher/auth`,
          undefined,
          await getAccessTokenSilently({
            audience: 'https://client-api.insidedesk.com',
          }),
        ),
      );
      let webSession: GoldfingerWebSession;
      try {
        webSession = await client.createSession(channel);

        webSession.onStatus(onStatus);

        webSession.onInputRequest(onInputRequest);

        webSession.onSessionCompletion(onSessionComplete);

        webSession.onHeartbeatFailure(() => {
          onHeartbeatFailure();
          webSession.disconnectSession();
        });

        setSession(webSession);
      } catch (error: unknown) {
        if (
          propertyIn(error, 'message') &&
          typeof error.message === 'string' &&
          error.message.includes(EventType.SESSION_BEGIN)
        ) {
          onHeartbeatFailure();
        } else {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
    }

    if (channelID !== null && session === undefined) {
      runGoldfingerSession(channelID);
    }

    return () => {
      if (session !== undefined) {
        session.disconnectSession();
      }
    };
  }, [
    channelID,
    getAccessTokenSilently,
    onHeartbeatFailure,
    onInputRequest,
    onSessionComplete,
    onStatus,
    session,
  ]);

  return session;
}
