import { atom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { PersistedPagination, UserFilterBy, UserSortBy } from 'types';
import { CascadingStorage } from 'utils';
import { DEFAULT_USER_FILTER, DEFAULT_USER_SORT } from '../constants';

/**
 * XXX: If you add a new storage atom, be sure to load it in the AtomPreloader
 * component and set it to a non-default value in AtomPreloader.test.tsx to
 * prevent future regressions.
 */

const storage = new CascadingStorage('users');

const paginationStorageAtom = atomWithStorage<PersistedPagination>(
  'pagination',
  { page: 0, rowsPerPage: 25 },
  createJSONStorage(() => storage),
);

export const pageAtom = atom(
  (get) => get(paginationStorageAtom).page,
  (get, set, page: number) => {
    set(paginationStorageAtom, (prev) => ({ ...prev, page }));
  },
);

export const rowsPerPageAtom = atom(
  (get) => get(paginationStorageAtom).rowsPerPage,
  (get, set, rowsPerPage: number) => {
    set(paginationStorageAtom, (prev) => ({ ...prev, page: 0, rowsPerPage }));
  },
);

export const searchAtom = atom(undefined, (_, set, search: string) => {
  set(searchAtom, search);
  set(pageAtom, 0);
});

const sortStorageAtom = atomWithStorage(
  'sort',
  DEFAULT_USER_SORT,
  createJSONStorage(() => storage),
);

export const sortByAtom = atom(
  (get) => get(sortStorageAtom).sortBy,
  (get, set, sortBy: UserSortBy) => {
    set(sortStorageAtom, (prev) => ({ ...prev, sortBy }));
    set(pageAtom, 0);
  },
);

export const sortOrderAtom = atom(
  (get) => get(sortStorageAtom).order,
  (get, set) => {
    set(sortStorageAtom, (prev) => ({
      ...prev,
      order: prev.order === 'asc' ? 'desc' : 'asc',
    }));
    set(pageAtom, 0);
  },
);

const filterStorageAtom = atomWithStorage(
  'filter',
  DEFAULT_USER_FILTER,
  createJSONStorage(() => storage),
);

export const filterByAtom = atom(
  (get) => get(filterStorageAtom),
  (get, set, filterBy: UserFilterBy) => {
    set(filterStorageAtom, filterBy);
    set(pageAtom, 0);
  },
);
