import {
  formatISOToAmerican,
  formatTitle,
  isNotNullish,
  LabelledCell,
  MaskFullStory,
  useFlags,
} from '@insidedesk/tuxedo';
import { Card, CardProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RedactedCredential } from 'types';
import { formatTaxId } from 'utils';
import { CredentialStatusChip } from '../CredentialStatusChip';

interface CredentialListItemProps extends CardProps {
  credential: RedactedCredential;
}

export default function CredentialListItem(props: CredentialListItemProps) {
  const { credential, ...rest } = props;
  const flags = useFlags();
  const navigate = useNavigate();

  const gridTemplateColumns = `[insurer] 20fr ${
    flags.addCredentialOpenClaimsField ? '[openClaims] 20fr' : ''
  } [taxID] 20fr ${
    flags.removeCredentialLastAccessedField
      ? '[user] 25fr'
      : '[user] 15fr [lastAccessed] 15fr'
  } [status] 25fr`;
  return (
    <Card
      data-testid='credential-list-item'
      variant='outlined'
      {...rest}
      sx={{
        alignItems: 'center',
        display: 'grid',
        gap: 1,
        ...(flags.uniformCredentialListColumns
          ? { gridColumn: '1 / -1', gridTemplateColumns: 'subgrid' }
          : { gridTemplateColumns }),
        padding: 2,
        ...rest.sx,
      }}
      onClick={() => navigate(`${credential.id}`)}
    >
      <LabelledCell
        label='Insurer Portal'
        value={credential.insurer ? formatTitle(credential.insurer) : '-'}
        sx={{ gridColumn: 'insurer' }}
      />
      {flags.addCredentialOpenClaimsField &&
        (isNotNullish(credential.unresolved_claims) ? (
          <LabelledCell
            label='# Of Open Claims'
            value={credential.unresolved_claims}
            sx={{ gridColumn: 'openClaims' }}
          />
        ) : (
          <div style={{ gridColumn: 'openClaims' }} />
        ))}
      <LabelledCell
        label='Provider Tax ID'
        value={formatTaxId(credential.tax_id)}
        phi
        sx={{ gridColumn: 'taxID' }}
      />
      <MaskFullStory>
        <LabelledCell
          label='User'
          value={credential.username}
          phi
          sx={{ gridColumn: 'user' }}
        />
      </MaskFullStory>
      {flags.removeCredentialLastAccessedField ? null : (
        <LabelledCell
          label='Last Accessed'
          value={
            credential.last_accessed
              ? formatISOToAmerican(credential.last_accessed)
              : 'Never'
          }
          sx={{ textAlign: 'end', gridColumn: 'lastAccessed' }}
        />
      )}
      <CredentialStatusChip
        credential={credential}
        sx={{ gridColumn: 'status', justifySelf: 'end' }}
      />
    </Card>
  );
}
