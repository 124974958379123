import { useAuth0 } from '@auth0/auth0-react';
import {
  MainNavbar,
  NavbarLayout as TuxedoNavbarLayout,
} from '@insidedesk/tuxedo';
import { NAVBAR_CONFIG } from 'config';
import { PropsWithChildren } from 'react';

export default function NavbarLayout({ children }: PropsWithChildren) {
  const { logout } = useAuth0();
  return (
    <TuxedoNavbarLayout
      navbar={
        <MainNavbar
          active='credentials'
          assist={NAVBAR_CONFIG.assistUrl}
          iq={NAVBAR_CONFIG.iqUrl}
          credentials='/credentials'
          users='/users'
          help={NAVBAR_CONFIG.helpUrl}
          remit={NAVBAR_CONFIG.remitUrl}
          logout={() => logout({ returnTo: window.location.origin })}
          showBlurToggle
        />
      }
    >
      {children}
    </TuxedoNavbarLayout>
  );
}
