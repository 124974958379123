import { CenterInPage, LoadingSpinner } from '@insidedesk/tuxedo';
import { Box, Stack, StackProps } from '@mui/material';
import { DetailHeader } from 'components';
import { Suspense } from 'react';

const overflowProps = {
  height: '100%',
  maxHeight: '100%',
  overflow: 'auto',
};

export interface DetailLayoutProps extends StackProps {}
export function DetailLayout({ children, ...rest }: DetailLayoutProps) {
  return (
    <Stack pt={2} rowGap={1.5} {...overflowProps} {...rest}>
      <DetailHeader />
      <Box p={3} bgcolor='white' {...overflowProps}>
        <Suspense
          fallback={
            <CenterInPage>
              <LoadingSpinner />
            </CenterInPage>
          }
        >
          {children}
        </Suspense>
      </Box>
    </Stack>
  );
}
