import { useClientAPIMutationFn } from '@insidedesk/tuxedo';
import { useMutation } from '@tanstack/react-query';
import { UserPostArgs } from 'types';

export default function useCreateUser() {
  return useMutation(
    useClientAPIMutationFn<void, UserPostArgs>('users/', {
      method: 'POST',
    }),
  );
}
