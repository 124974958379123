import { ClientAPIContext, useHasPermission } from '@insidedesk/tuxedo';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useContext } from 'react';
import {
  filterByAtom,
  pageAtom,
  rowsPerPageAtom,
  searchAtom,
  sortByAtom,
  sortOrderAtom,
} from 'state/user-page';
import { Pagination, User, UsersQueryParams } from 'types';
import { createSearchParams } from 'utils';

export default function useUsersQuery() {
  const clientAPI = useContext(ClientAPIContext);
  if (!clientAPI) throw new Error('ClientAPIContext not found');

  const { hasPermission } = useHasPermission();

  const queryParams: UsersQueryParams = {
    search: useAtomValue(searchAtom),
    filter_by: useAtomValue(filterByAtom),
    sort_by: useAtomValue(sortByAtom),
    sort_order: useAtomValue(sortOrderAtom),
    page_size: useAtomValue(rowsPerPageAtom),
    page: useAtomValue(pageAtom) + 1,
  };

  const queryString = createSearchParams(queryParams).toString();

  const { data, ...rest } = useQuery(
    ['user-list', queryString],
    async () => {
      const response = await clientAPI.fetch(`users/?${queryString}`);
      if (!response.ok) {
        throw response;
      }
      const pagination = JSON.parse(response.headers.get('x-pagination') ?? '');
      return { users: (await response.json()) as User[], pagination };
    },
    {
      enabled: hasPermission('read:users'),
      keepPreviousData: true,
      suspense: true,
    },
  );

  return {
    users: data?.users,
    pagination: data?.pagination as Pagination | undefined,
    ...rest,
  };
}
