import { isNotNullish, useHasPermission } from '@insidedesk/tuxedo';
import { PropsWithChildren } from 'react';
import { useMatches } from 'react-router-dom';
import { RouteHandle } from 'types';

export default function PermissionGuard({ children }: PropsWithChildren) {
  const { hasAllPermissions } = useHasPermission();
  const matches = useMatches();
  const permissions = matches
    .map((match) => match.handle as RouteHandle | undefined)
    .map((handle) => handle?.permission)
    .filter(isNotNullish);
  if (hasAllPermissions(...permissions)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  throw new Response(`You need ${permissions} to see this content`, {
    status: 403,
  });
}
