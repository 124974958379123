export default function getURL(url?: string) {
  if (!url) {
    return '';
  }
  try {
    // If the url is correctly formatted return the hostname
    return new URL(url).hostname;
  } catch {
    // If the url is not, return the string before the first /
    // removing any path in order to make the string shorter
    return url?.substring(0, url.indexOf('/'));
  }
}
