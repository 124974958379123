import { Typography } from '@mui/material';
import { CSSProperties, useId } from 'react';
import LoadingGif from './loading.gif';

export default function LoadingSpinner(props: {
  description?: string;
  imgStyleProps?: CSSProperties;
}) {
  const { description, imgStyleProps } = props;
  const labelId = useId();
  return (
    <div
      role='progressbar'
      aria-labelledby={description !== undefined ? labelId : undefined}
    >
      <img
        src={LoadingGif}
        alt='Loading'
        style={{ display: 'block', margin: 'auto', ...imgStyleProps }}
      />
      {description && (
        <Typography id={labelId} sx={{ fontWeight: 600, textAlign: 'center' }}>
          {description}
        </Typography>
      )}
    </div>
  );
}
