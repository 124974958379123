import { HTTPError, useSnackbar } from '@insidedesk/tuxedo';
import { AddEditUserForm } from 'components';

import { useQueryClient } from '@tanstack/react-query';
import { useCreateUser, useUserRoles } from 'hooks';
import useFacilitiesQuery from 'hooks/useFacilitiesQuery';
import { useNavigate } from 'react-router-dom';
import { UserPostArgs } from 'types';

export default function AddUserPage() {
  const queryClient = useQueryClient();
  const createUserMutation = useCreateUser();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { data: roles } = useUserRoles();
  const { data: facilityOptions } = useFacilitiesQuery();

  const createUser = (data: UserPostArgs) => {
    createUserMutation.mutate(data, {
      async onSuccess() {
        await queryClient.invalidateQueries(['user-list'], {
          type: 'all',
        });
        await queryClient.invalidateQueries(['user-status-counts'], {
          type: 'all',
        });
        enqueueSnackbar({
          variant: 'success',
          message: 'User created successfully!',
        });
        navigate('/users', { replace: true });
      },
      onError(e) {
        const { status } = (e as HTTPError).response;
        const message =
          status === 409
            ? `Email ${data.email} already exists.`
            : 'Failed to create user.';
        enqueueSnackbar({
          variant: 'error',
          message,
        });
      },
    });
  };

  if (roles && facilityOptions) {
    return (
      <AddEditUserForm
        roles={roles}
        facilityOptions={facilityOptions}
        createUser={createUser}
        isSubmitting={
          createUserMutation.isLoading || createUserMutation.isSuccess
        }
      />
    );
  }
  return null;
}
