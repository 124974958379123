import { withAuthenticationRequired } from '@auth0/auth0-react';
import {
  BlurPHIProvider,
  CenterInPage,
  ClientAPIProvider,
  CommonUtilityWrapper,
  ErrorBoundary,
  ErrorElement,
  FlagProvider,
  HTTPError,
  LoadingSpinner,
  provider,
  ProviderStack,
  SnackbarProvider,
  useRefreshToken,
  UserProfileProvider,
} from '@insidedesk/tuxedo';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AtomPreloader } from 'components';
import { FULL_STORY_CONFIG, PENDO_CONFIG } from 'config';
import { Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import routes from 'routes';
import { noticeError } from './utils';

const pendoConfig = {
  stageId: PENDO_CONFIG.stageId,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      retry: (failureCount, error) => {
        if (error instanceof HTTPError) {
          if (error.response.status === 404 || error.response.status === 401) {
            return false;
          }
        }
        return failureCount < 1;
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
  queryCache: new QueryCache({
    onError: (err) => noticeError(err),
  }),
  mutationCache: new MutationCache({
    onError: (err) => noticeError(err),
  }),
});

function App() {
  const tokenPromise = useRefreshToken();

  return (
    <ErrorBoundary fallbackComponent={ErrorElement}>
      <ProviderStack
        providers={[
          provider(ClientAPIProvider, {
            baseURL: process.env.REACT_APP_CLIENT_API_URL || '',
            tokenPromise: () => tokenPromise,
          }),
          provider(UserProfileProvider, { tokenPromise }),
          provider(QueryClientProvider, { client: queryClient }),
          provider(FlagProvider, {
            clientSideID: process.env.REACT_APP_LD_CLIENT_ID ?? '',
            useClientContext: true,
          }),
          provider(SnackbarProvider, {}),
          provider(BlurPHIProvider, {}),
        ]}
      >
        <CommonUtilityWrapper
          pendoConfig={pendoConfig}
          fullStoryConfigOptions={FULL_STORY_CONFIG}
        >
          <ReactQueryDevtools initialIsOpen={false} />
          <Suspense
            fallback={
              <CenterInPage>
                <LoadingSpinner />
              </CenterInPage>
            }
          >
            <AtomPreloader />
            <RouterProvider router={createBrowserRouter(routes)} />
          </Suspense>
        </CommonUtilityWrapper>
      </ProviderStack>
    </ErrorBoundary>
  );
}

export default withAuthenticationRequired(App, {
  onRedirecting: function RedirectSpinner() {
    return (
      <CenterInPage>
        <LoadingSpinner />
      </CenterInPage>
    );
  },
});
