import { useClientAPIQueryFn } from '@insidedesk/tuxedo';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { RedactedCredential } from 'types';

export default function useCredentialQuery(
  id: number,
  options: UseQueryOptions<RedactedCredential> = {},
) {
  const queryFn = useClientAPIQueryFn<RedactedCredential>(
    `admin/credentials/${id}`,
  );
  const query = useQuery({ queryKey: ['credential', id], queryFn, ...options });
  return query;
}
