import { useAtomValue } from 'jotai';
import {
  collectorTypeAtom,
  filterByAtom as credentialFilterByAtom,
  pageAtom as credentialPageAtom,
  rowsPerPageAtom as credentialRowsPerPageAtom,
  taxIdAtom,
} from 'state/credential-page';
import {
  filterByAtom as userFilterByAtom,
  pageAtom as userPageAtom,
  rowsPerPageAtom as userRowsPerPageAtom,
  sortByAtom,
} from 'state/user-page';

/**
 * Used to preload atoms that are persisted in local/session storage to avoid
 * making multiple requests to the API.
 * @returns null
 */
export default function AtomPreloader() {
  // credential list atoms
  useAtomValue(taxIdAtom);
  useAtomValue(credentialFilterByAtom);
  useAtomValue(credentialPageAtom);
  useAtomValue(credentialRowsPerPageAtom);
  useAtomValue(collectorTypeAtom);

  // user list atoms
  useAtomValue(userFilterByAtom);
  useAtomValue(sortByAtom);
  useAtomValue(userPageAtom);
  useAtomValue(userRowsPerPageAtom);

  return null;
}
