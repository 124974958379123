import {
  HTTPError,
  TwoToneHeader,
  useClientAPIMutationFn,
  useSnackbar,
} from '@insidedesk/tuxedo';
import { Close, LockPersonOutlined } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CredentialAddEditForm } from 'components';
import { useCollectorList } from 'hooks';
import { useState } from 'react';

export type CredentialPostParams = {
  tax_id: string;
  collector_id: number;
  username: string;
  password: string;
};

function usePostCredential() {
  return useMutation<void, HTTPError, CredentialPostParams>(
    useClientAPIMutationFn<void, CredentialPostParams>('vault/credentials/', {
      method: 'POST',
    }),
  );
}

export default function CredentialAddModal(props: {
  open: boolean;
  onClose: () => void;
  taxIds: string[];
}) {
  const { open, onClose, taxIds } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: collectors } = useCollectorList();
  const postCredential = usePostCredential();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (data: CredentialPostParams) => {
    setIsSubmitting(true);
    postCredential.mutate(data, {
      onSuccess: () => {
        queryClient
          .invalidateQueries({
            queryKey: ['credentialList'],
            refetchType: 'all',
          })
          .then(() => {
            enqueueSnackbar({
              message: 'Credential successfully created!',
              variant: 'success',
            });
            onClose();
          })
          .finally(() => setIsSubmitting(false));
      },
      onError: (error: HTTPError) => {
        enqueueSnackbar({
          message:
            error.response.status === 409
              ? 'A credential for that portal with that username already exists.'
              : 'Something went wrong.',
          variant: 'error',
        });
        setIsSubmitting(false);
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { p: 2.5 } }}
      fullWidth
      maxWidth='md'
    >
      <DialogTitle>
        <TwoToneHeader
          text={['', 'Add Insurer Credentials']}
          icon={<LockPersonOutlined />}
          // Quell h1 can't appear as child of h2 error
          variant='body1'
          sx={{ fontSize: '1.5rem' }}
        />
        <IconButton
          onClick={onClose}
          role='button'
          aria-label='close'
          sx={{ position: 'absolute', right: '20px', top: '20px' }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CredentialAddEditForm
          taxIds={taxIds}
          collectors={collectors ?? []}
          addCredential={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </DialogContent>
    </Dialog>
  );
}
