/* eslint-disable no-empty-function */
/* eslint-disable no-useless-constructor */
import { SyncStringStorage } from 'jotai/vanilla/utils/atomWithStorage';

export default class CascadingStorage implements SyncStringStorage {
  public constructor(private namespace: string) {}

  public getItem(key: string) {
    return (
      sessionStorage.getItem(this.fullKey(key)) ??
      localStorage.getItem(this.fullKey(key))
    );
  }

  public setItem(key: string, value: string) {
    sessionStorage.setItem(this.fullKey(key), value);
    localStorage.setItem(this.fullKey(key), value);
  }

  public removeItem(key: string) {
    sessionStorage.removeItem(this.fullKey(key));
    localStorage.removeItem(this.fullKey(key));
  }

  private fullKey(key: string) {
    return `${this.namespace}.${key}`;
  }
}
