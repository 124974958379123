import { isNotNullish, TwoToneHeader } from '@insidedesk/tuxedo';
import { ArrowBackIos } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { Link, useMatches } from 'react-router-dom';
import { RouteHandle } from 'types';

export default function DetailHeader() {
  const matches = useMatches();
  const handles = matches
    .map((match) => match.handle as RouteHandle | undefined)
    .filter(isNotNullish);

  const breadcrumbs = handles
    .map((handle) => handle.breadcrumb)
    .filter((breadcrumb) => Boolean(breadcrumb));

  const titles = handles
    .map((handle) => handle.title)
    .filter((title) => Boolean(title));
  const titleLeaf = titles[titles.length - 1] ?? ['Settings'];

  const parentPathname = matches[matches.length - 2]?.pathname ?? '/';

  return (
    <Stack direction='row' alignItems='center' sx={{ ml: 3 }}>
      <IconButton component={Link} to={parentPathname} aria-label='Back'>
        <ArrowBackIos sx={{ fontSize: '45px' }} />
      </IconButton>
      <Stack alignItems='start' ml={-2}>
        <TwoToneHeader variant='h1' text={titleLeaf} />
        <Typography variant='breadcrumb'>{breadcrumbs.join(' / ')}</Typography>
      </Stack>
    </Stack>
  );
}
