import {
  CenterInPage,
  ListLayout as TuxedoListLayout,
  LoadingSpinner,
  PanelCard,
  TwoToneHeader,
} from '@insidedesk/tuxedo';
import { SettingsOutlined } from '@mui/icons-material';
import { SideBar } from 'components';
import { PropsWithChildren, Suspense } from 'react';

export interface ListLayoutProps extends PropsWithChildren {}

export function ListLayout({ children }: ListLayoutProps) {
  return (
    <TuxedoListLayout
      header={<TwoToneHeader icon={<SettingsOutlined />} text={['settings']} />}
      sidebar={
        <PanelCard variant='outlined' side='left' sx={{ pt: 2 }}>
          <SideBar />
        </PanelCard>
      }
    >
      <PanelCard
        variant='outlined'
        side='right'
        sx={{ position: 'relative', pt: 1 }}
      >
        <Suspense
          fallback={
            <CenterInPage>
              <LoadingSpinner />
            </CenterInPage>
          }
        >
          {children}
        </Suspense>
      </PanelCard>
    </TuxedoListLayout>
  );
}
